import React from 'react';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import Select from 'react-select';
import Typography from "@mui/material/Typography";
import MKInput from "components/MKInput";

const FilterForm = ({
  filterOptions,
  filterValues,
  setFilterValues,
  onFilterChange,
}) => {
  const handleChange = (selectedOption, field) => {
    const newValue = selectedOption ? selectedOption.value : ''; 
    setFilterValues((prevValues) => {
      const newValues = { ...prevValues, [field]: newValue }; 
      onFilterChange(newValues); 
      return newValues;
    });
  };

  return (
    <Row>
      {filterOptions.map(({ label, id, type, options }, idx) => (
        <Col lg="3" sm="6" key={idx}>
          <FormGroup>

            {type === 'select' ? (
              <Select
                id={id}
                options={options.map((opt) => ({
                  label: opt.title,  
                  value: opt.id,     
                }))}
                onChange={(value) => handleChange(value, id)} 
                value={filterValues[id] ? { label: options.find(opt => opt.id === filterValues[id])?.title, value: filterValues[id] } : null}
                isClearable
                placeholder={`Выберите ${label}`} 
                styles={{
                  control: (base) => ({
                    ...base,
                    marginTop:'15px',
                    borderRadius: '5px', 
                    borderColor: '#ddd',
                    fontSize: '14px', 
                    height: '45px', 
                    '&:hover': {
                      borderColor: '#007bff',
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: '8px', 
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    fontSize: '14px',
                  }),
                  option: (base) => ({
                    ...base,
                    fontSize: '14px',
                  }),
                  singleValue: (base) => ({
                    ...base,
                    fontSize: '14px', 
                  }),
                }}
              />
            ) : (
              <MKInput
              id={id}
              fullWidth
              type="text"
              value={filterValues[id] || ''}
              onChange={(e) => handleChange({ value: e.target.value }, id)}
              required
              margin="normal"
              placeholder={`Введите ${label}`} 
              sx={{
                borderRadius: '8px',
                borderColor: '#ddd',
                fontSize: '14px',
                height: '45px',
                backgroundColor: '#fff', 
              }}
            />
            
            )}
          </FormGroup>
        </Col>
      ))}
    </Row>
  );
};

export default FilterForm;
