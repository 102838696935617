import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import routes from "common/routes";
import Container from "@mui/material/Container";
import axios from "axios";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";
import { BASE_URL } from "app/api";

const UploadPhotoForm = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Файл выбран:", file.name);
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.warning("Выберите фотографию", { autoClose: 3000 });
      return;
    }
    if (isUploading) return;
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
       await axios.post(`${BASE_URL}/api/photo`, formData);
      setIsUploading(false);
      setSelectedFile(null);

      setShowAlert(true);
      setAlertMessage("Файл успешно загружен");
      setAlertType("success");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } catch (error) {
      setIsUploading(false);
      setSelectedFile(null);
      setShowAlert(true);
      setAlertMessage("Ошибка при загрузке, попробуйте еще раз!");
      setAlertType("success");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" minHeight="80vh" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                py={4}
                px={3}
                border="1px solid #ccc"
                borderRadius={4}
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                textAlign="center"
              >
                <Typography variant="h4" gutterBottom>
                  Загрузить фотографию
                </Typography>
                <label htmlFor="file-input">
                  <MKButton
                    component="span"
                    variant="contained"
                    color="info"
                    disabled={isUploading}
                  >
                    Выбрать файл
                  </MKButton>
                  <input
                    id="file-input"
                    type="file"
                    name="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>
                {selectedFile && (
                  <Typography variant="subtitle1" mt={2}>
                    {selectedFile.name}
                  </Typography>
                )}
                <MKButton
                  variant="contained"
                  color="success"
                  onClick={handleUpload}
                  disabled={isUploading}
                  sx={{ mt: 2 }}
                >
                  {isUploading ? "Загрузка..." : "Загрузить"}
                </MKButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>    </>
  );
};

UploadPhotoForm.propTypes = {
  onUploadSuccess: PropTypes.func.isRequired,
};

export default UploadPhotoForm;