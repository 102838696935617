import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import axiosClient from '../axiosClient';
import routes from "common/routes";
import footerRoutes from "common/footer-routes";
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import Stack from "@mui/material/Stack";
import { jwtDecode } from "jwt-decode";
import MKPagination from "components/Pagination/MKPagination";
import FilterForm from '../FilterForm';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [userId, setUserId] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filterValues, setFilterValues] = useState({
      categoryTypeId: '',
      ticketTypeId: '',
      description: '',
      title: '',
      sort: 'desc'
    });
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
  
    const getInitialUserId = () => {
      const token = localStorage.getItem("accessToken");
      if (!token) return null;
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.userId || null;
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
        return null;
      }
    };
  
    useEffect(() => {
      const initialUserId = getInitialUserId();
      setUserId(initialUserId);
    }, []);
  
    useEffect(() => {
      const fetchCategoriesAndTypes = async () => {
        try {
          const response = await axiosClient.get("/api/common-reference/by-type/002");
          const data = response.data.data;
          setCategories(data.filter(item => item.parentId === null));
          setTicketTypes(data.filter(item => item.parentId !== null));
        } catch (error) {
          console.error("Ошибка при загрузке категорий и типов:", error);
        }
      };
      fetchCategoriesAndTypes();
    }, []);
  
    const fetchTickets = async (page, filters = {}) => {
      if (!userId) return; 
      try {
        const response = await axiosClient.get('/api/ticket/by-filters', {
          params: {
            userId,
            page: page - 1,
            myTicket: true,
            size: 10,
            ...filters,
          },
        });
        setTickets(response.data.data.content || []);
        setTotalPages(response.data.data.totalPages || 1);
      } catch (error) {
        console.error("Ошибка загрузки тем", error);
      }
    };
  
    useEffect(() => {
      if (userId) fetchTickets(currentPage, filterValues);
    }, [currentPage, userId]);

  const filterOptions = [
    { label: "Категория", id: "categoryTypeId", type: "select", options: categories }, 
    { label: "Тип", id: "ticketTypeId", type: "select", options: ticketTypes }, 
    { label: "Описание", id: "description", type: "text", options: [] },
    { label: "Наименование", id: "title", type: "text", options: [] },
  ];
  

  const handleFilterChange = (newFilters) => {
    setFilterValues(newFilters);
    fetchTickets(currentPage, newFilters);
  };

  useEffect(() => {
    fetchTickets(currentPage); 
  }, [currentPage]);


  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={0} mx={0} pt={16}
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Container maxWidth={false} disableGutters>
        <FilterForm
            filterOptions={filterOptions}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            onFilterChange={handleFilterChange} 
          />
   

   {tickets.map((ticket) => (
            <Card
              key={ticket.id}
              sx={{
                p: 3,
                cursor: 'pointer',
                width: '100%',
                height: '160px',
                boxSizing: 'border-box',
                mb: 2,
                display: 'flex',       
                flexDirection: 'column',  
                justifyContent: 'space-between', 
              }}
              onClick={() => navigate(`/ticket/${ticket.id}`, { state: { ticket } })}
            >
              <Typography variant="h6">{ticket.title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {ticket.description && ticket.description.length > 100
                  ? `${ticket.description.slice(0, 100)}...`
                  : ticket.description || 'Нет описания'}
              </Typography>

              <MKBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 'auto', 
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  @{ticket.userName}
                </Typography>
                <MKBox sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="caption" color="textSecondary">
                    {ticket.createdAt &&
                      formatDistanceToNow(new Date(ticket.createdAt), {
                        addSuffix: true,
                        locale: ru,
                      })}
                  </Typography>
                  <ChatBubbleOutlineIcon fontSize="small" />
                  <Typography variant="caption" color="textSecondary">
                    {ticket.commentCount || 0}
                  </Typography>
                </MKBox>
              </MKBox>
            </Card>
          ))}
               <Stack direction="row" justifyContent="center" sx={{ mt: 4 }}>
                   <MKPagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
          
          </Stack>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default MyTickets;
