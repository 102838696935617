import React, { useState } from "react";
import axiosClient from "app/axiosClient";
import { Box, Typography, Card, Grid, MenuItem, Select, InputLabel, TextField, FormControl } from "@mui/material";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import MKBox from "components/MKBox";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

const CreateUserForm = ({ onUserCreateSuccess }) => {
  const [role, setRole] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const roles = [
    { title: "ADMIN" },
    { title: "USER" },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newUser = {
      role,
      login,
      password,
      phone,
      address,
      email,
      firstName,
      lastName,
      age: parseInt(age),
    };

    try {
      setIsSubmitting(true);
      await axiosClient.post("/api/auth/signup", newUser);
      if (onUserCreateSuccess) onUserCreateSuccess();
      setRole("");
      setLogin("");
      setPassword("");
      setPhone("");
      setAddress("");
      setEmail("");
      setFirstName("");
      setLastName("");
      setAge("");
      setAlertMessage("Пользователь успешно создан!");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.response?.data?.message || "Ошибка при обновлении пользователя");
      setAlertType("error");
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsSubmitting(false);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Box px={3} py={5}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Card elevation={3} sx={{ p: 3 }}>
                <Typography variant="h4" mb={3} align="center">
                  Создать Пользователя
                </Typography>
                <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" required>
                    <InputLabel>Категория</InputLabel>
                    <Select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      label="Роль"
                      sx={{
                        height: "48px",
                        fontSize: "1rem", 
                      }}
                    >
                      <MenuItem value="" disabled>Выберите роль</MenuItem>
                      {roles.map((role) => (
                        <MenuItem key={role.title} value={role.title}>
                          {role.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField label="Логин" fullWidth value={login} onChange={(e) => setLogin(e.target.value)} required margin="normal" />
                  <TextField label="Пароль" fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)} required margin="normal" />
                  <TextField label="Телефон" fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} required margin="normal" />
                  <TextField label="Адрес" fullWidth value={address} onChange={(e) => setAddress(e.target.value)} required margin="normal" />
                  <TextField label="Email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} required margin="normal" />
                  <TextField label="Имя" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} required margin="normal" />
                  <TextField label="Фамилия" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} required margin="normal" />
                  <TextField label="Возраст" fullWidth type="number" value={age} onChange={(e) => setAge(e.target.value)} required margin="normal" />
                  
                  <Box mb={2} textAlign="center">
                    <MKButton type="submit" variant="contained" color="info" fullWidth disabled={isSubmitting}>
                      {isSubmitting ? "Создание..." : "Создать пользователя"}
                    </MKButton>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </MKBox>

      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </>
  );
};

export default CreateUserForm;
