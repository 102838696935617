import React, { useEffect, useState, useRef, useCallback } from 'react';
import axiosClient from 'app/axiosClient';
import { Typography, Avatar, List, ListItem, Box, Divider, Paper, TextField, Stack, IconButton, CircularProgress } from '@mui/material';
import MKButton from 'components/MKButton';
import { BASE_URL } from 'app/api';
import { jwtDecode } from "jwt-decode";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

const ChatPartnersPage = ({ initialReceiverId }) => {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [avatars, setAvatars] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
    }
  }, []);

  const fetchUserById = async (userIdToFetch) => {
    try {
      const response = await axiosClient.get(`/api/user/${userIdToFetch}`);
      console.info('Fetching user by ID');
      return {
        user: {
          id: response.data.data.id,
          username: response.data.data.username || `User_${response.data.data.id}`,
        },
        unreadMessages: 0,
      };
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      return {
        user: {
          id: userIdToFetch,
          username: `Unknown_${userIdToFetch}`,
        },
        unreadMessages: 0,
      };
    }
  };

  useEffect(() => {
    if (!userId) return;

    axiosClient.get(`/api/chat/users?userId=${userId}`)
      .then(async response => {
        let updatedPartners = response.data || [];
        setPartners(updatedPartners);
        response.data.forEach(partner => fetchAvatar(partner.user.id));

        if (initialReceiverId) {
          let initialPartner = updatedPartners.find(p => p.user.id === initialReceiverId);
          if (!initialPartner) {
            const newPartner = await fetchUserById(initialReceiverId);
            if (newPartner) {
              updatedPartners = [...updatedPartners, newPartner];
              setPartners(updatedPartners);
              initialPartner = newPartner;
            }
          }
          if (initialPartner) {
            setSelectedPartner(initialPartner);
            fetchMessages(initialPartner.user.id, 0);
          } else {
            const fallbackPartner = {
              user: { id: initialReceiverId, username: `User_${initialReceiverId}` },
              unreadMessages: 0,
            };
            setSelectedPartner(fallbackPartner);
            setPartners(prev => [...prev, fallbackPartner]);
            fetchMessages(initialReceiverId, 0);
          }
        }
      })
      .catch(error => console.error('Error fetching partners:', error));
  }, [userId, initialReceiverId]);

  const fetchAvatar = (partnerId) => {
    axiosClient.get(`${BASE_URL}/api/attachments/download/avatar/user/${partnerId}`, { responseType: 'arraybuffer' })
      .then(response => {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setAvatars(prev => ({ ...prev, [partnerId]: imageUrl }));
      })
      .catch(() => {
        setAvatars(prev => ({ ...prev, [partnerId]: 'default-avatar.jpg' }));
      });
  };

  const fetchMessages = (partnerId, pageToLoad = 0) => {
    if (!partnerId || !userId) return;

    const container = messagesContainerRef.current;
    const previousScrollHeight = container ? container.scrollHeight : 0;

    axiosClient
      .get(`/api/chat/messages?userId1=${userId}&userId2=${partnerId}&page=${pageToLoad}&size=20`)
      .then(response => {
        const fetchedMessages = response.data.content || [];
        if (pageToLoad === 0) {
          setMessages(fetchedMessages);
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 0);
        } else {
          setMessages(prev => [...(fetchedMessages || []), ...prev]);
          setTimeout(() => {
            if (container) {
              container.scrollTop = container.scrollHeight - previousScrollHeight;
            }
          }, 0);
        }
        setHasMore(!response.data.last);

        if (pageToLoad === 0) {
          const messageIds = fetchedMessages
            .filter(msg => msg.senderId === partnerId && msg.receiverId === userId && !msg.isRead)
            .map(msg => msg.id);

          if (messageIds.length > 0) {
            axiosClient
              .post('/api/chat/mark-as-read', { messageIds, userId })
              .then(() => {
                setMessages(prevMessages =>
                  prevMessages.map(msg =>
                    messageIds.includes(msg.id) ? { ...msg, isRead: true } : msg
                  )
                );
                setPartners(prev =>
                  prev.map(p =>
                    p.user.id === partnerId ? { ...p, unreadMessages: 0 } : p
                  )
                );
              })
              .catch(error => console.error('Error marking messages as read:', error));
          }
        }
      })
      .catch(error => console.error('Error fetching messages:', error));
  };

  const handleSelectPartner = (partner) => {
    if (!partner?.user?.id) return;
    setSelectedPartner(partner);
    setPage(0);
    fetchMessages(partner.user.id, 0);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '' || !selectedPartner?.user?.id || !userId) return;

    const tempMessage = {
      id: Date.now(),
      senderId: userId,
      content: newMessage,
      timestamp: new Date().toISOString(),
      status: 'sending'
    };

    setMessages(prev => [...prev, tempMessage]);
    setNewMessage('');

    axiosClient.post(`/api/chat/send`, null, {
      params: { senderId: userId, receiverId: selectedPartner.user.id, content: newMessage }
    })
      .then(response => {
        setMessages(prev => prev.map(msg => msg.id === tempMessage.id ? { ...response.data, status: 'sent' } : msg));
        if (!partners.some(p => p.user.id === selectedPartner.user.id)) {
          setPartners(prev => [...prev, selectedPartner]);
        }
      })
      .catch(error => {
        console.error('Error sending message:', error);
        setMessages(prev => prev.map(msg => msg.id === tempMessage.id ? { ...msg, status: 'failed' } : msg));
      });
  };

  useEffect(() => {
    if (selectedPartner?.user?.id) {
      const interval = setInterval(() => fetchMessages(selectedPartner.user.id, 0), 10000);
      return () => clearInterval(interval);
    }
  }, [selectedPartner]);

  const handleScroll = useCallback(() => {
    if (messagesContainerRef.current.scrollTop === 0 && hasMore && selectedPartner?.user?.id) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchMessages(selectedPartner.user.id, nextPage);
    }
  }, [page, hasMore, selectedPartner]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = { hour: '2-digit', minute: '2-digit'};
    return date.toLocaleTimeString('ru-RU', options);
  };

  const groupedMessages = messages.reduce((acc, message) => {
    const date = formatDate(message.timestamp);
    acc[date] = acc[date] ? [...acc[date], message] : [message];
    return acc;
  }, {});

  const isMobile = window.innerWidth <= 768;

  return (
    <Box display="flex" height="87vh">
      {(isMobile && !selectedPartner) || !isMobile ? (
        <Box sx={{ width: isMobile ? '100%' : '30%', bgcolor: 'background.paper', overflowY: 'auto', p: 2 }}>
          <List>
            {partners.map(partner => (
              <ListItem
                key={partner.user.id}
                button
                onClick={() => handleSelectPartner(partner)}
                sx={{ p: 2, borderRadius: 2, mb: 1, bgcolor: selectedPartner && selectedPartner.user.id === partner.user.id ? '#E3F2FD' : 'transparent', ":hover": { bgcolor: '#B3E5FC' } }}
              >
                <Avatar src={avatars[partner.user.id]} sx={{ width: 50, height: 50, mr: 2 }} />
                <Typography variant="h6" fontWeight="medium" sx={{ flex: 1 }}>
                  {partner.user.username}
                </Typography>
                {partner.unreadMessages > 0 && (
                  <Box bgcolor="#B3E5FC" color="white" borderRadius="50%" width={24} height={24} display="flex" justifyContent="center" alignItems="center" fontSize={12}>
                    {partner.unreadMessages}
                  </Box>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      ) : null}

      {!isMobile && <Divider orientation="vertical" flexItem />}

      {(!isMobile || selectedPartner) && (
        <Box flex={1} display="flex" flexDirection="column" p={2} position="relative">
          {selectedPartner ? (
            <>
              <Box display="flex" alignItems="center" mb={2}>
                {isMobile && (
                  <IconButton onClick={() => setSelectedPartner(null)}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Avatar src={avatars[selectedPartner.user.id]} sx={{ width: 48, height: 48, mr: 2 }} />
                <Typography variant="h6" fontWeight="bold">{selectedPartner.user.username}</Typography>
              </Box>

              <Box ref={messagesContainerRef} flex={1} overflow="auto" bgcolor="#f9f9f9" p={2} borderRadius={2} onScroll={handleScroll}>
                <Stack spacing={3}>
                  {Object.entries(groupedMessages).map(([date, msgs]) => (
                    <Box key={date}>
                      <Typography variant="caption" display="block" textAlign="center" color="gray" mb={1}>{date}</Typography>
                      {msgs.map(msg => (
                        <Box key={msg.id} display="flex" justifyContent={msg.senderId === userId ? 'flex-end' : 'flex-start'} position="relative" mb={2}>
                          <Paper
                            sx={{
                              p: 1.5,
                              pb: 1,
                              borderRadius: 3,
                              maxWidth: '100%',
                              width: 'auto',
                              bgcolor: msg.senderId === userId ? '#BBDEFB' : '#E0F7FA',
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word',
                              boxSizing: 'border-box',
                            }}
                          >
                            <Typography variant="body2">{msg.content}</Typography>
                            <Typography variant="caption" textAlign="right" color="gray">
                              {formatTime(msg.timestamp)}
                            </Typography>
                          </Paper>
                          {msg.senderId === userId && (
                            <Box display="flex" justifyContent="flex-end" mt={0.5} ml={0.5}>
                              {msg.status === 'sending' ? <CircularProgress size={12} /> : <CheckIcon fontSize="small" color="success" />}
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ))}
                  <div ref={messagesEndRef} />
                </Stack>
              </Box>

              <Box display="flex" mt={2}>
                <TextField
                  fullWidth
                  placeholder="Введите сообщение..."
                  value={newMessage}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  onChange={(e) => setNewMessage(e.target.value)}
                  sx={{ mr: 2 }}
                />
                <MKButton variant="contained" color="info" onClick={handleSendMessage}>Отправить</MKButton>
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography variant="h6">Выберите собеседника</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ChatPartnersPage;