import React from "react";
import { Table, Container } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';

const TableComponent = ({ headers, fields, data, navigateId, activeTab }) => {
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    if (activeTab === 0) { 
      const userId = row.id;
      if (userId) {
        console.log("Переход на профиль:", row);
        navigate(`/profile/${userId}`, { state: { user: row.user || row } });
      }
    } else if (activeTab === 1 || activeTab === 2 || activeTab === 3) { 
      const ticketId = row.ticketId || (row.tickets && row.tickets.id) || row.id;
      if (ticketId) {
        console.log("Переход на тикет:", row);
        navigate(`/ticket/${ticketId}`, { state: { ticket: row.tickets || row } });
      }
    }
  };

  return (
    <Container>
      <div style={{ height: "600px", overflowY: "auto" }}>
        {data.length === 0 ? (
          <Typography variant="h6" align="center">Записей нету</Typography>
        ) : (
          <Table bordered striped hover responsive>
            <thead style={{ position: "sticky", top: 0, backgroundColor: "#f8f9fa", zIndex: 1 }}>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} style={{ textAlign: "center", fontSize: "14px", padding: "12px", fontWeight: "bold", color: "#495057" }}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={() => handleRowClick(row)}
                  style={{
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                    '&:hover': {
                      backgroundColor: "#e9ecef"
                    }
                  }}
                >
                  {fields.map((field, colIndex) => {
                    const value = field.split(".").reduce((acc, key) => acc?.[key], row);
                    return (
                      <td key={colIndex} style={{ textAlign: "center", padding: "12px",fontSize: "14px", }}>
                        {value || "-"}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Container>
  );
};

export default TableComponent;
