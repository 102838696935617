import { useState } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SimpleModal({ 
  title = "Модальное окно", 
  content = "Это универсальный текст по умолчанию.", 
  onClose, 
  onConfirm, 
  confirmText = "Сохранить", 
  cancelText = "Закрыть", 
  triggerButtonText = "Открыть модальное окно"
}) {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    toggleModal();
  };

  const handleClose = () => {
    if (onClose) onClose();
    toggleModal();
  };

  return (
    <>
      <MKTypography
        variant="button"
        fontWeight="regular"
        color="info"
        onClick={toggleModal}
        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
      >
        &nbsp;&nbsp;Я разрешаю на обработку персональных данных. Личные данные останутся анонимными, кроме логина.
      </MKTypography>

      <Modal open={show} onClose={handleClose} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={show} timeout={500}>
          <MKBox
            position="relative"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">{title}</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={handleClose} />
            </MKBox>

            <Divider sx={{ my: 0 }} />

            <MKBox p={2} sx={{ maxHeight: "70vh", overflowY: "auto" }}> {/* Allow scrolling if content is too long */}
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
                <p>Пользовательское Соглашение</p>
                <p>Настоящее Пользовательское Соглашение (далее — «Соглашение») регулирует порядок использования вами услуг Реабилитационного Анонимного Центра (далее — «Центр»), включая форум и другие сервисы, предоставляемые Центром. Регистрируясь и используя данные сервисы, вы подтверждаете своё согласие со всеми условиями данного Соглашения.</p>

                <h6>1. Возрастные ограничения</h6>
                <p>Использование сервисов Центра разрешено только лицам, достигшим 18 лет. Регистрируясь, вы подтверждаете, что вам исполнилось 18 лет. Центр не несёт ответственности за использование сервисов лицами, предоставившими неверные данные о своём возрасте.</p>

                <h6>2. Обработка персональных данных</h6>
                <p>Вы даёте своё согласие на обработку, хранение и использование ваших персональных данных в соответствии с законодательством. Центр обязуется использовать ваши данные исключительно для обеспечения функционирования сервисов и улучшения их качества. Ваши данные не подлежат разглашению третьим лицам, за исключением случаев, предусмотренных законом.</p>

                <h6>3. Ограничение ответственности</h6>
                <p>Центр не несёт ответственности за возможный моральный, психологический, материальный или любой иной ущерб, возникший в результате:</p>
                <ul>
                  <li>использования или невозможности использования сервисов Центра;</li>
                  <li>действий третьих лиц на форуме и в других сервисах Центра;</li>
                  <li>потери данных или несанкционированного доступа к вашей информации.</li>
                </ul>

                <h6>4. Права и обязанности пользователей</h6>
                <p>Вы соглашаетесь:</p>
                <ul>
                  <li>предоставлять достоверную информацию при регистрации;</li>
                  <li>соблюдать нормы этики, уважать права и свободы других пользователей;</li>
                  <li>не размещать материалы, нарушающие законы, права третьих лиц, а также:</li>
                  <ul>
                    <li>содержащие оскорбления, угрозы, клевету, разжигание ненависти или вражды;</li>
                    <li>пропагандирующие насилие, дискриминацию по любым признакам;</li>
                    <li>материалы эротического, порнографического или экстремистского характера;</li>
                    <li>информацию, способную причинить вред другим пользователям или Центру.</li>
                  </ul>
                </ul>

                <h6>5. Отказ от претензий</h6>
                <p>Используя сервисы Центра, вы отказываетесь от любых претензий к Центру, его администрации, сотрудникам и партнёрам, связанных с использованием сервисов, включая, но не ограничиваясь, претензиями по поводу морального или эмоционального ущерба.</p>

                <h6>6. Изменение условий Соглашения</h6>
                <p>Центр оставляет за собой право вносить изменения в данное Соглашение без предварительного уведомления. Актуальная версия всегда доступна на сайте Центра. Продолжение использования сервисов после внесения изменений означает ваше согласие с ними.</p>

                <p>Регистрируясь, вы подтверждаете, что вам исполнилось 18 лет, вы ознакомлены с условиями данного Соглашения, полностью их понимаете и принимаете.</p>
              </MKTypography>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>
    </>
  );
}

export default SimpleModal;
