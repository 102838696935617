import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Home from "app/page";
import theme from "assets/theme";
import routes from "common/routes";
import CreateUserForm from "app/employee/CreateUserForm";
import UploadNewsForm from "app/news/UploadNewsForm";
import NewsSection from "app/home/sections/NewsSection";
import NewsDetailPage from "app/home/sections/NewsDetailPage";
import UploadPhotoForm from "./components/UploadPhotoForm";
import TicketDetailsPage from "app/test/TicketDetailsPage";
import CreateTicketForm from "app/Forum/CreateTicketForm";
import MyTickets from "app/profile/MyTickets";
import ProfileDetailPage from "app/profile/ProfileDetailPage";
import SignUp from "app/signup/Register";
import PasswordReset from "app/sign-in/PasswordReset";
import ChatPage from "app/chat/ChatPage";
import HisTicketsPage from "app/profile/HisTicketsPage";
import NewsEdit from "app/news/NewsEdit";
import TicketEdit from "app/Forum/TicketEdit";
export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={index} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/create-user" element={<CreateUserForm />} />
        <Route path="/create-news" element={<UploadNewsForm />} />
        <Route path="/ticket/create" element={<CreateTicketForm />} />
        <Route path="/my-tickets" element={<MyTickets />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/forgot-password" element={<PasswordReset />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/his-tickets" element={<HisTicketsPage />} />
        <Route path="/ticket-edit" element={<TicketEdit />} />
        
        <Route path="/add-photo" element={<UploadPhotoForm />} />
        <Route path="/news" element={<NewsSection />} />
        <Route path="/news-edit" element={<NewsEdit />} />

        <Route path="/news/:id" element={<NewsDetailPage />} />
        <Route path="/profile/:userId" element={<ProfileDetailPage />} />
        <Route path="/ticket/:ticketId" element={<TicketDetailsPage />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
