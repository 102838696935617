import React from 'react';
import { Grid, Card, Typography, Container } from '@mui/material';
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import MKBox from "components/MKBox";
import ChatPartnersPage from './ChatPartnersPage';
import { useLocation } from 'react-router-dom';

export default function ChatPage() {
  const location = useLocation();
  const receiverId = location.state?.receiverId;

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        component="section"
        variant="gradient"
        position="relative"
        py={6}
        px={{ xs: 2, lg: 0 }}
        mx={0}
        pt={11}
        pb={0}
        style={{ height: '100vh' }}
      >
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Card>
                <ChatPartnersPage initialReceiverId={receiverId} />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}