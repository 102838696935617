import React, { useState, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import axiosClient from "app/axiosClient";
import routes from "common/routes";
import { Box, Typography, Card, Grid, TextField, CircularProgress } from "@mui/material";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import MKBox from "components/MKBox";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";
import { useLocation } from "react-router-dom"; // Добавляем useLocation

const NewsEdit = ({ onEditSuccess }) => {
  const location = useLocation();
  const news = location.state?.news || {}; // Получаем news из state или пустой объект

  const [title, setTitle] = useState(news.title || "");
  const [description, setDescription] = useState(news.description || "");
  const [content, setContent] = useState(news.content || "");
  const [userId, setUserId] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(news.imageName || "");
  const [filePreview, setFilePreview] = useState(news.image || null); // Используем news.image вместо news.imageUrl
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
      }
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleResetFile = () => {
    setFile(null);
    setFileName(news.imageName || "");
    setFilePreview(news.image || null);
    fileInputRef.current.value = null;
  };

  const updatePhoto = async (newsId) => {
    if (!file) return;
    setIsUploading(true);

    const formData = new FormData();
    const attachmentDto = {
      type: "NEWS_IMAGE",
      originName: file.name,
      description: "News related image",
      newsId: newsId,
    };

    formData.append("dto", JSON.stringify(attachmentDto));
    formData.append("file", file);

    try {
      await axiosClient.post("/api/attachments/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFile(null);
      setFileName(file.name);
      setFilePreview(URL.createObjectURL(file));
    } catch (error) {
      console.error("Ошибка обновления фото:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!news.id) {
      setAlertMessage("Ошибка: ID новости отсутствует!");
      setAlertType("error");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      return;
    }

    try {
      const response = await axiosClient.put(`/api/news/${news.id}`, {
        title,
        description,
        content,
        userId,
        isPublished: news.isPublished || false,
        isArchived: news.isArchived || false,
      });
      if (response.status === 200) {
        await updatePhoto(news.id);
        if (onEditSuccess) onEditSuccess();
        setAlertMessage("Новость успешно обновлена!");
        setAlertType("success");
      }
    } catch (error) {
      console.error("Ошибка при обновлении новости:", error);
      setAlertMessage("Ошибка при обновлении новости, попробуйте снова!");
      setAlertType("error");
    } finally {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" height="98vh" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Box px={3} py={5}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Card elevation={3} sx={{ p: 3 }}>
                <Typography variant="h4" mb={3} align="center">
                  Редактировать Новость
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField label="Заголовок" fullWidth value={title} onChange={(e) => setTitle(e.target.value)} required margin="normal" />
                  <TextField label="Описание" fullWidth value={description} onChange={(e) => setDescription(e.target.value)} required margin="normal" />
                  <TextField label="Содержание" fullWidth multiline rows={4} value={content} onChange={(e) => setContent(e.target.value)} required margin="normal" />
                  <Box mb={2} textAlign="center">
                    <label htmlFor="file-input">
                      <MKButton 
                        variant="contained" 
                        color="info" 
                        onClick={() => fileInputRef.current.click()}
                      >
                        Выбрать фотографию
                      </MKButton>
                      <input
                        id="file-input"
                        ref={fileInputRef}
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }} 
                      />
                    </label>
                    {fileName && (
                      <Box mt={2}>
                        <Typography>Выбранный файл: {fileName}</Typography>
                        {filePreview && (
                          <Box mt={1}>
                            <img src={filePreview} alt="Предпросмотр" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            <Box mt={1}>
                              <MKButton
                                variant="outlined"
                                color="error"
                                onClick={handleResetFile}
                              >
                                Сбросить фото
                              </MKButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                  <Box mt={2} textAlign="center">
                    <MKButton type="submit" variant="contained" color="info" fullWidth disabled={isUploading || !news.id}>
                      {isUploading ? <CircularProgress size={24} /> : "Сохранить изменения"}
                    </MKButton>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </>
  );
};

export default NewsEdit;