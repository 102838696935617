import InformationIcon from "assets/images/navbar/Информация.png";
import GalleryIcon from "assets/images/navbar/Галерея.png";
import AboutUsIcon from "assets/images/navbar/О нас.png";
import ServicesIcon from "assets/images/navbar/Услуги.png";
import NewsIcon from "assets/images/navbar/Новости.png";
import ContactUsIcon from "assets/images/navbar/Связаться.png";
import ProfileIcon from "assets/images/navbar/profile.png";

import EmployeeIcon from "assets/images/navbar/Сотрудники.png";
import ForumIcon from "assets/images/navbar/speech-bubble.png";

// Pages
import Services from "app/services/page";
import InfoPage from "app/info/page";
import ContactUs from "app/contact-us/page";
import SignIn from "app/sign-in/page";
import AboutUs from "app/about-us/page";
import Gallery from "app/gallery/page";
import Employee from "app/employee";
import NewsPage from "app/news/NewsPage";
import { isUserAuthenticated } from "./auth";
import SignOutButton from "components/Navbars/DefaultNavbar/SignOut";
import ProfilePage from "app/profile/ProfilePage";
import AdminTabs from "app/admin/AdminTabs";
import ForumPage from "app/Forum/ForumPage";
import ChatPage from "app/chat/ChatPage";

const iconStyle = {
  width: "15px", 
  height: "15px", 
};

const iconStyleForum = {
  width: "17px", 
  height: "17px", 
};

const isAuth = isUserAuthenticated();
const userRole = localStorage.getItem('role');

const routes = [
  {
    name: "О нас",
    icon: <img src={AboutUsIcon} style={iconStyle} />,
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Информация",
    icon: <img src={InformationIcon} style={iconStyle} />,
    route: "/info",
    component: <InfoPage />,
  },
  {
    name: "Услуги",
    icon: <img src={ServicesIcon} style={iconStyle} />,
    route: "/services",
    component: <Services />,
  },
  {
    name: "Галерея",
    icon: <img src={GalleryIcon} style={iconStyle} />,
    route: "/gallery",
    component: <Gallery />,
  },
  {
    icon: <img src={NewsIcon} style={iconStyle} />,
    name: "Новости",
    route: "/news",
    component: <NewsPage />,
  },
  {
    type: "button",
    icon: <img src={ForumIcon} style={iconStyleForum} />,
    name: "Форум",
    route: "/tickets",
    component: <ForumPage />,
  },
  {
    name: "Сотрудники",
    icon: <img src={EmployeeIcon} style={iconStyle} />,
    route: "/employee",
    component: <Employee />,
  },
  isAuth ? {
    name: "Профиль",
    columns: 1,
    icon: <img src={ProfileIcon} style={iconStyle} />,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Аккаунт",
        collapse: [
          {
            type: "external",
            name: "Профиль",
            route: "/profile",
            label: "Профиль",
            color: "info",
            component: <ProfilePage />,
          },
          {
            type: "external",
            name: "Мои сообщения",
            route: "/messanger",
            label: "Пользователи",
            color: "info",
            component: <ChatPage />,
          },
          {
            type: "button",
            name: "Выйти",
            route: "/sign-out",
            icon: <img src={ContactUsIcon} style={iconStyle} />,
            component: <SignOutButton />,
          },
        ],
      },
      (userRole === 'ADMIN' || userRole === 'EMPLOYEE') && {
        name: "Управление",
        collapse: [
          {
            type: "external",
            name: "Админ панель",
            route: "/users",
            label: "Пользователи",
            color: "info",
            component: <AdminTabs />,
          },
        ],
      },
    ].filter(Boolean),
  } : {
    type: "external",
    name: "Войти",
    route: "/sign-in",
    label: "Войти",
    color: "info",
    component: <SignIn />,
  },
];

export default routes;
