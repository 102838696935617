import React, { useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import axiosClient from './axiosClient';
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

const LikeDislikeButton = ({ userId, commentId, initialLikeCount, initialDislikeCount, isLikedByCurrentUser: initialIsLiked, isDislikedByCurrentUser: initialIsDisliked, onLikeDislikeUpdate }) => {
  const [likeCount, setLikeCount] = useState(initialLikeCount || 0);
  const [dislikeCount, setDislikeCount] = useState(initialDislikeCount || 0);
  const [isLiked, setIsLiked] = useState(initialIsLiked || false);
  const [isDisliked, setIsDisliked] = useState(initialIsDisliked || false);
  const [isAuthenticated, setIsAuthenticated] = useState(userId !== null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const handleLikeDislike = async (isLike) => {
    try {
      if (!isAuthenticated) {
        setShowAlert(true);
        setAlertMessage("Пожалуйста авторизуйтесь или зарегистрируйтесь");
        setAlertType("info");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        return;
      }

      if (isLike) {
        if (isLiked) {
          await axiosClient.post(`/api/like/comment/${commentId}/delete?userId=${userId}`);
          setLikeCount(likeCount - 1);
          setIsLiked(false);
        } else {
          if (isDisliked) {
            await axiosClient.post(`/api/like/comment/${commentId}/delete?userId=${userId}`);
            setDislikeCount(dislikeCount - 1);
            setIsDisliked(false);
          }
          await axiosClient.post(`/api/like/likes?userId=${userId}&commentId=${commentId}`);
          setLikeCount(likeCount + 1);
          setIsLiked(true);
        }
      } else {
        if (isDisliked) {
          await axiosClient.post(`/api/like/comment/${commentId}/delete?userId=${userId}`);
          setDislikeCount(dislikeCount - 1);
          setIsDisliked(false);
        } else {
          if (isLiked) {
            await axiosClient.post(`/api/like/comment/${commentId}/delete?userId=${userId}`);
            setLikeCount(likeCount - 1);
            setIsLiked(false);
          }
          await axiosClient.post(`/api/like/dislike?userId=${userId}&commentId=${commentId}`);
          setDislikeCount(dislikeCount + 1);
          setIsDisliked(true);
        }
      }

      onLikeDislikeUpdate(commentId, { likeCount: isLike ? (isLiked ? likeCount - 1 : likeCount + 1) : likeCount, dislikeCount: !isLike ? (isDisliked ? dislikeCount - 1 : dislikeCount + 1) : dislikeCount });
    } catch (error) {
      console.error("Ошибка при изменении лайка или дизлайка", error);
    }
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton
        color={isLiked ? "primary" : "inherit"}
        onClick={() => handleLikeDislike(true)}
        sx={{ fontSize: 18 }}
      >
        <ThumbUpIcon />
      </IconButton>
      {likeCount}

      <IconButton
        color={isDisliked ? "error" : "inherit"} 
        onClick={() => handleLikeDislike(false)}
        sx={{ fontSize: 18 }}
      >
        <ThumbDownIcon />
      </IconButton>
      {dislikeCount}

      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </Stack>
  );
};

export default LikeDislikeButton;