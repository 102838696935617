import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SimpleModal from "layouts/sections/attention-catchers/modals/components/SimpleModal";
import { BASE_URL } from "app/api";
import ConfirmationCodeInput from "./ConfirmationCodeInput";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

function SignUp() {
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [age, setAge] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Новое состояние для загрузки

  const navigate = useNavigate();

  const handleSetAgreeTerms = () => setAgreeTerms(!agreeTerms);

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();
    if (agreeTerms) {
      setIsLoading(true); // Устанавливаем загрузку перед запросом
      try {
        const response = await axios.post(`${BASE_URL}/api/auth/signup`, {
          login: username,
          email: email,
          password: password,
          phone: phone,
          address: address,
          age: age,
          firstName: firstName,
          lastName: lastName,
        });
        if (response.status === 201 || response.status === 200) {
          setShowAlert(true);
          setAlertMessage("Код подтверждения отправлен на вашу почту");
          setAlertType("success");
          setTimeout(() => setShowAlert(false), 3000);
          setStep(2);
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || err.message;
        setShowAlert(true);
        setAlertMessage(errorMessage);
        setAlertType("error");
        setTimeout(() => setShowAlert(false), 3000);
      } finally {
        setIsLoading(false); // Сбрасываем загрузку после завершения
      }
    }
  };

  const handleConfirmationSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Устанавливаем загрузку перед запросом
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/confirm`, null, {
        params: {
          email: email,
          code: confirmationCode,
        },
      });
      if (response.status === 200) {
        setShowAlert(true);
        setAlertMessage("Регистрация успешно подтверждена. Пожалуйста, войдите в систему.");
        setAlertType("success"); // Исправлено с "error" на "success"
        setTimeout(() => {
          setShowAlert(false);
          navigate("/sign-in");
        }, 3000);
      }
    } catch (err) {
      setShowAlert(true);
      setAlertMessage(err.response?.data?.message || err.message);
      setAlertType("error");
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsLoading(false); // Сбрасываем загрузку после завершения
    }
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Устанавливаем загрузку перед запросом
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/resend-verification-code?email=${email}`);
      if (response.status === 200) {
        alert(response.data.message || "Новый код подтверждения отправлен на вашу почту");
      }
    } catch (err) {
      setShowAlert(true);
      setAlertMessage(err.response?.data?.message || err.message);
      setAlertType("error");
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsLoading(false); // Сбрасываем загрузку после завершения
    }
  };

  const renderRegistrationForm = () => (
    <MKBox component="form" role="form" onSubmit={handleRegistrationSubmit}>
      <MKBox mb={2}>
        <MKInput type="text" label="Логин" fullWidth value={username} onChange={(e) => setUsername(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="email" label="Email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="password" label="Пароль" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="text" label="Телефон" fullWidth value={phone} onChange={(e) => setPhone(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="text" label="Адрес" fullWidth value={address} onChange={(e) => setAddress(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="number" label="Возраст" fullWidth value={age} onChange={(e) => setAge(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="text" label="Имя" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput type="text" label="Фамилия" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </MKBox>
      <MKBox display="flex" alignItems="center" ml={-1}>
        <Switch checked={agreeTerms} onChange={handleSetAgreeTerms} />
        <SimpleModal
          title="Условия пользования"
          onConfirm={() => console.log("Данные сохранены!")}
          onClose={() => console.log("Модальное окно закрыто")}
          confirmText="Подтвердить"
          cancelText="Отмена"
          triggerButtonText="Открыть регистрацию"
        />
      </MKBox>
      <MKBox mt={4} mb={1}>
        <MKButton
          type="submit"
          variant="gradient"
          color="info"
          fullWidth
          disabled={!agreeTerms || isLoading} // Отключаем кнопку при загрузке
        >
          {isLoading ? "Загрузка..." : "Зарегистрироваться"}
        </MKButton>
      </MKBox>
    </MKBox>
  );

  const renderConfirmationForm = () => (
    <MKBox component="form" role="form" onSubmit={handleConfirmationSubmit}>
      <MKBox mb={3}>
        <MKTypography variant="body2" color="text" textAlign="center">
          Мы отправили код подтверждения на <strong>{email}</strong>
        </MKTypography>
      </MKBox>
      <MKBox mb={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "300px", mx: "auto" }}>
        <ConfirmationCodeInput confirmationCode={confirmationCode} setConfirmationCode={setConfirmationCode} />
      </MKBox>
      <MKBox mt={4} mb={2}>
        <MKButton
          type="submit"
          variant="gradient"
          color="info"
          fullWidth
          disabled={isLoading} // Отключаем кнопку при загрузке
        >
          {isLoading ? "Загрузка..." : "Подтвердить регистрацию"}
        </MKButton>
      </MKBox>
      <MKBox textAlign="center">
        <MKTypography variant="button" color="text">
          Не получили код?{" "}
          <MKTypography
            component="a"
            href="#"
            variant="button"
            color="info"
            fontWeight="medium"
            onClick={handleResendCode}
            sx={{ pointerEvents: isLoading ? "none" : "auto", opacity: isLoading ? 0.5 : 1 }} // Отключаем клик при загрузке
          >
            {isLoading ? "Загрузка..." : "Отправить еще раз"}
          </MKTypography>
        </MKTypography>
      </MKBox>
    </MKBox>
  );

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {step === 1 ? "Регистрация" : "Подтверждение"}
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {step === 1 ? renderRegistrationForm() : renderConfirmationForm()}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: "fixed", top: 20, left: "50%", transform: "translateX(-50%)", zIndex: 999 }}>
          <MKAlert color={alertType === "success" ? "success" : "error"}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </>
  );
}

export default SignUp;