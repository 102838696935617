import React from "react";
import NewsSection from "../home/sections/NewsSection"; 
import MKBox from "components/MKBox";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import routes from "common/routes";
import footerRoutes from "common/footer-routes";

const NewsPage = () => {
  console.log('Stored accessToken:', localStorage.getItem("accessToken"));

  return (
    <>
      <DefaultNavbar routes={routes} sticky />

      <NewsSection />
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default NewsPage;
