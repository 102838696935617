import React, { useState, useEffect } from 'react';
import { Card, Typography, Grid, Stack, Avatar, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import LikeDislikeButton from '../LikeDislikeButton';
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from 'app/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MKButton from "../../components/MKButton";
import DeleteIcon from '@mui/icons-material/Delete';

const CommentSection = ({ comments: initialComments }) => {
  const [userId, setUserId] = useState(null);
  const [avatars, setAvatars] = useState({});
  const [comments, setComments] = useState(initialComments);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const navigate = useNavigate();
  const currentUserRole = localStorage.getItem('role');

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
      }
    }
  }, []);

  useEffect(() => {
    setComments(initialComments);
  }, [initialComments]);

  const fetchAvatar = (commentUserId) => {
    if (!avatars[commentUserId]) {
      axios.get(`${BASE_URL}/api/attachments/download/avatar/user/${commentUserId}`, {
        responseType: 'arraybuffer'
      })
        .then(response => {
          const imageUrl = URL.createObjectURL(new Blob([response.data]));
          setAvatars(prevAvatars => ({
            ...prevAvatars,
            [commentUserId]: imageUrl
          }));
        })
        .catch(error => {
          console.error('Error loading avatar:', error);
        });
    }
  };

  const handleMessageClick = (commentUserId) => {
    navigate('/chat', { state: { receiverId: commentUserId } });
  };

  const handleDeleteClick = (commentId) => {
    setCommentToDelete(commentId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (commentToDelete) {
      try {
        await axios.delete(`${BASE_URL}/api/comment/${commentToDelete}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        });
        setComments(prevComments => prevComments.filter(comment => comment.id !== commentToDelete));
      } catch (error) {
        console.error('Ошибка при удалении комментария:', error);
      }
    }
    setOpenDeleteDialog(false);
    setCommentToDelete(null);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setCommentToDelete(null);
  };

  return (
    <>
      <Typography variant="h5" mb={2}>Комментарии</Typography>
      {comments.map(comment => {
        fetchAvatar(comment.userId);
        const isOwner = userId === comment.userId;
        const isAdmin = currentUserRole === 'ADMIN';

        return (
          <Card key={comment.id} sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={2} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Avatar
                  src={avatars[comment.userId]}
                  sx={{ width: 48, height: 48, mb: 1 }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ mb: 1 }}>

              {new Date(comment.createdAt).toLocaleString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })}
                </Typography>
                {userId !== comment.userId && ( 
                  <MKButton
                    variant="contained"
                    color="info"
                    onClick={() => handleMessageClick(comment.userId)}
                    sx={{
                      mt: 2,
                      padding: '4px 12px',
                      fontSize: '0.75rem',
                      width: 'auto',
                    }}
                  >
                    Написать сообщение
                  </MKButton>
                )}
              </Grid>

              <Grid item xs={10}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1">{comment.userName}</Typography>
                  {(isOwner || isAdmin) && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteClick(comment.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Stack>
                <Typography variant="body2" color="text.secondary">{comment.content}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <LikeDislikeButton
                    userId={userId}
                    commentId={comment.id}
                    initialLikeCount={comment.likeCount}
                    initialDislikeCount={comment.disLikeCount}
                    initialUserStatus={comment.userStatus}
                    isDislikedByCurrentUser={comment.isDislikedByCurrentUser}
                    isLikedByCurrentUser={comment.isLikedByCurrentUser}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        );
      })}

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Подтверждение удаления"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Вы уверены, что хотите удалить этот комментарий? Это действие нельзя отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Отмена</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommentSection;