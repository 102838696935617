import React, { useState, useEffect } from "react";
import axiosClient from "../axiosClient";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import TableComponent from "../TabelComponent";
import routes from "common/routes";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import MKButton from "components/MKButton";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import FilterForm from "../FilterForm";
import { jwtDecode } from "jwt-decode";

function AdminTabs() {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValues, setFilterValues] = useState({});
  const [categories, setCategories] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateIds = {
    0: "id",
    1: "id",
    2: "ticketId",
    3: "id",
  };

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.userId);
      } catch (error) {
        console.error("Ошибка декодирования токена:", error);
      }
    }
  }, []);

  // Восстановление состояния из URL при загрузке
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = parseInt(params.get("tab")) || 0;
    const page = parseInt(params.get("page")) || 1;
    const filters = {};
    params.forEach((value, key) => {
      if (key !== "tab" && key !== "page") {
        filters[key] = value;
      }
    });

    setActiveTab(tab);
    setCurrentPage(page);
    setFilterValues(filters);
  }, [location.search]);

  const fetchData = () => {
    let endpoint = "";
    if (activeTab === 0) endpoint = "api/user/by-filters";
    else if (activeTab === 1) endpoint = "api/ticket/moderation/by-filters";
    else if (activeTab === 2) endpoint = "api/comment/by-filters";
    else if (activeTab === 3) endpoint = "api/ticket/by-filters";

    axiosClient
      .get(endpoint, {
        params: {
          page: currentPage - 1,
          size: 20,
          userId: userId,
          isCreated: activeTab === 3 ? true : undefined,
          ...filterValues,
        },
      })
      .then((response) => {
        const rawData = response.data.data.content || [];
        const transformedData =
          activeTab === 0
            ? rawData.map((item) => ({
                ...item,
                isBlocked: item.isBlocked ? "Заблокирован" : "Активен",
              }))
            : rawData;
        setData(transformedData);
        setTotalPages(response.data.data.totalPages || 1);
      })
      .catch((error) => {
        console.error("Ошибка при получении данных:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [activeTab, currentPage, filterValues, userId]);

  useEffect(() => {
    const fetchCategoriesAndTypes = async () => {
      try {
        const response = await axiosClient.get("/api/common-reference/by-type/002");
        const data = response.data.data;
        const parentCategories = data.filter((item) => item.parentId === null);
        setCategories(parentCategories);
        setTicketTypes(data.filter((item) => item.parentId !== null));
      } catch (error) {
        console.error("Ошибка при загрузке категорий и типов:", error);
      }
    };

    fetchCategoriesAndTypes();
  }, []);

  const filterOptions = {
    0: [
      { label: "Логин", id: "username", type: "text" },
      { label: "Email", id: "email", type: "text" },
      { label: "Телефон", id: "phone", type: "text" },
    ],
    1: [
      { label: "Категория", id: "categoryTypeId", type: "select", options: categories },
      { label: "Тип", id: "ticketTypeId", type: "select", options: ticketTypes },
      { label: "Наименование", id: "title", type: "text" },
      { label: "Описание", id: "description", type: "text" },
    ],
    2: [
      { label: "Пользователь", id: "username", type: "text" },
      { label: "Текст", id: "content", type: "text" },
    ],
    3: [
      { label: "Категория", id: "categoryTypeId", type: "select", options: categories },
      { label: "Тип", id: "ticketTypeId", type: "select", options: ticketTypes },
      { label: "Наименование", id: "title", type: "text" },
      { label: "Описание", id: "description", type: "text" },
    ],
  };

  const headers = {
    0: ["id", "Логин", "Email", "Роль", "Статус", "Телефон", "Адрес"],
    1: ["ID", "Заголовок", "Пользователь", "Статус", "Дата создания"],
    2: ["Текст", "Пользователь", "Тема №", "Дата"],
    3: ["ID", "Заголовок", "Пользователь", "Статус", "Дата создания"],
  };

  const fields = {
    0: ["id", "username", "email", "role", "isBlocked", "phone", "address"],
    1: ["id", "title", "userName", "statusName", "createdAt"],
    2: ["content", "userName", "tickets.id", "createdAt"],
    3: ["id", "title", "userName", "statusName", "createdAt"],
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    updateUrl({ tab: activeTab, page: value, ...filterValues });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1);
    updateUrl({ tab: newValue, page: 1, ...filterValues });
  };

  const handleFilterChange = (newFilterValues) => {
    setFilterValues(newFilterValues);
    setCurrentPage(1);
    updateUrl({ tab: activeTab, page: 1, ...newFilterValues });
  };

  const updateUrl = (params) => {
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, value);
      }
    });
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />

      <MKBox
        component="section"
        variant="gradient"
        position="relative"
        py={6}
        px={{ xs: 4, lg: 4 }}
        mx={-2}
        pt={16}
      >
        <Container>
          <Stack direction="row" spacing={2} justifyContent="center">
            <MKButton variant="contained" color="info" onClick={() => navigate("/create-user")}>
              Создать пользователя
            </MKButton>
            <MKButton variant="contained" color="info" onClick={() => navigate("/create-news")}>
              Создать новость
            </MKButton>
            <MKButton variant="contained" color="info" onClick={() => navigate("/add-photo")}>
              Добавить фото в галерею
            </MKButton>
          </Stack>

          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ p: 2, m: 3 }}>
                <AppBar position="static">
                  <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Пользователи" />
                    <Tab label="Модерация тем" />
                    <Tab label="Комментарии" />
                    <Tab label="Мои модерации" />
                  </Tabs>
                </AppBar>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <FilterForm
                    filterOptions={filterOptions[activeTab]}
                    filterValues={filterValues}
                    setFilterValues={handleFilterChange}
                    onFilterChange={handleFilterChange}
                  />
                  <TableComponent
                    headers={headers[activeTab]}
                    fields={fields[activeTab]}
                    data={data}
                    navigateId={navigateIds[activeTab]}
                    activeTab={activeTab}
                  />
                </Grid>

                <Grid container justifyContent="center">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="info"
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default AdminTabs;