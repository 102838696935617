import React, { useState, useRef } from "react";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

const ConfirmationCodeInput = ({ confirmationCode, setConfirmationCode }) => {
  const inputsRef = useRef([]);
  
  const handleChange = (index, event) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) return; // Разрешаем ввод только цифр

    const newCode = confirmationCode.split("");
    newCode[index] = value;
    setConfirmationCode(newCode.join(""));

    // Переключаем фокус на следующий инпут, если введена цифра
    if (value && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !confirmationCode[index] && index > 0) {
      // Удаляем символ и перемещаем фокус назад
      const newCode = confirmationCode.split("");
      newCode[index - 1] = "";
      setConfirmationCode(newCode.join(""));
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <MKBox
      display="flex"
      justifyContent="center"
      gap={1}
      sx={{ maxWidth: "320px", mx: "auto" }}
    >
      {[...Array(6)].map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={confirmationCode[index] || ""}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(el) => (inputsRef.current[index] = el)}
          style={{
            width: "40px",
            height: "50px",
            textAlign: "center",
            fontSize: "1.5rem",
            borderRadius: "8px",
            border: "2px solid #ccc",
            outline: "none",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            transition: "border-color 0.2s",
          }}
        />
      ))}
    </MKBox>
  );
};

export default ConfirmationCodeInput;
