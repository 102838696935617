import React, { useState, useEffect } from 'react';
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../axiosClient';
import axios from 'axios';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import routes from "common/routes";
import { BASE_URL } from 'app/api';
import footerRoutes from "common/footer-routes";
import MKAlert from "components/MKAlert"; 
import { CSSTransition } from 'react-transition-group'; 

const ProfileDetailPage = () => {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState({});
  const [isBlocked, setIsBlocked] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showAlert, setShowAlert] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosClient.get(`/api/user/${userId}`);
        setUserDetails(response.data.data);
        setIsBlocked(response.data.data.isBlocked);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
    fetchAvatar(userId);
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const fetchAvatar = (userId) => {
    axios.get(`${BASE_URL}/api/attachments/download/avatar/user/${userId}`, {
      responseType: 'arraybuffer'
    })
      .then(response => {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setAvatar(imageUrl);
      })
      .catch(error => {
        console.error('Error loading avatar:', error);
      });
  };

  const handleSave = async () => {
    try {
      const response = await axiosClient.put(`/api/user/update`, userDetails);
      setUserDetails(response.data.data);
    } catch (error) {
      console.error('Error saving user details:', error);
    }
  };

  const handleBlockToggle = async () => {
    try {
      const apiEndpoint = isBlocked ? '/api/user/unblock' : '/api/user/block';
      await axiosClient.post(apiEndpoint, { userId: userDetails.id, isBlocked: true, blockReason: 'test' });
      setIsBlocked(!isBlocked);

      setAlertMessage(isBlocked ? 'Пользователь успешно разблокирован.' : 'Пользователь успешно заблокирован.');
      setAlertType('success');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } catch (error) {
      console.error('Error toggling block status:', error);

      setAlertMessage('Что-то пошло не так при изменении статуса блокировки.');
      setAlertType('error');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      axiosClient.put(`${BASE_URL}/api/user/${userDetails.id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          setAvatar(URL.createObjectURL(new Blob([response.data]))); 
        })
        .catch(error => {
          console.error('Error updating avatar:', error);
        });
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="center">
              <Card sx={{ p: 3 }}>
                <Avatar 
                  src={avatar || 'default-avatar.jpg'} 
                  sx={{ width: 240, height: 240, cursor: 'pointer' }} 
                  onClick={() => document.getElementById('avatar-upload').click()} 
                />
                <input 
                  id="avatar-upload" 
                  type="file" 
                  style={{ display: 'none' }} 
                  onChange={handleAvatarChange} 
                />
                <Typography variant="h6" mt={2}>{userDetails.username}</Typography>
                <Stack direction="row" spacing={2} mt={2}>
      {userId !== userDetails.id && (
  <MKButton
    variant="contained"
    color="info"
    onClick={() => navigate('/chat', { state: { receiverId: userDetails.id } })}
    sx={{ mt: 2, padding: '4px 12px', fontSize: '0.75rem', width: 'auto' }}
  >
    Написать сообщение
  </MKButton>
)}                 
 <MKButton variant="contained" color="info" onClick={() => navigate('/his-tickets', { state: { userId } })}>Темы </MKButton></Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <MKBox display="grid" gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }} gap={2}>
                  {[ 
                    { label: 'Роль', field: 'role', disabled: true },
                    { label: 'Телефон', field: 'phone' },
                    { label: 'Адрес', field: 'address' },
                    { label: 'Email', field: 'email' },
                    { label: 'Возраст', field: 'age', type: 'number' },
                    { label: 'Имя', field: 'firstName' },
                    { label: 'Фамилия', field: 'lastName' }
                  ].map(({ label, field, type = 'text', disabled = false }) => (
                    <React.Fragment key={field}>
                      <Typography variant="body2">{label}</Typography>
                      <MKInput
                        type={type}
                        name={field}
                        value={userDetails[field] || ''}
                        disabled                        
                        onChange={handleInputChange}
                      />
                    </React.Fragment>
                  ))}
                </MKBox>

                <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                  <MKButton variant="contained" color={isBlocked ? "warning" : "error"} onClick={handleBlockToggle}>
                    {isBlocked ? "Разблокировать" : "Заблокировать"}
                  </MKButton>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <CSSTransition
        in={showAlert}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default ProfileDetailPage;
