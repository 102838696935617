import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { jwtDecode } from "jwt-decode";
import axiosClient from '../axiosClient';
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import routes from "common/routes";
import footerRoutes from "common/footer-routes";
import TicketDetails from './TicketDetails';
import CommentSection from './CommentSection';
import Grid from "@mui/material/Grid";
import { BASE_URL } from 'app/api';
import axios from 'axios';
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

const TicketDetailsPage = () => {
  const { ticketId } = useParams();
  const location = useLocation();
  const ticketData = location.state || {};

  const getInitialUserId = () => {
    const token = localStorage.getItem("accessToken");
    console.log('Initial Token from localStorage:', token);
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log('Initial Decoded token:', decodedToken); 
        return decodedToken.userId || null;
      } catch (error) {
        console.error("Ошибка декодирования токена при инициализации:", error);
        return null;
      }
    }
    return null;
  };

  const initialUserId = getInitialUserId(); 
  const [ticket, setTicket] = useState(location.state?.ticket || ticketData);
  const [avatar, setAvatar] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [userId, setUserId] = useState(initialUserId);
  const [isAuthenticated, setIsAuthenticated] = useState(!!initialUserId);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    console.log('useEffect Token from localStorage:', token); 
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        console.log('useEffect Decoded token:', decodedToken);
        if (decodedToken.userId !== userId) {
          setUserId(decodedToken.userId);
          setIsAuthenticated(!!decodedToken.userId);
        }
      } catch (error) {
        console.error("Ошибка декодирования токена в useEffect:", error);
        setUserId(null);
        setIsAuthenticated(false);
      }
    } else if (userId !== null) {
      setUserId(null);
      setIsAuthenticated(false);
    }
  }, []); 

  useEffect(() => {
    console.log('Fetching data with userId:', userId); 
    if (!ticket) {
      fetchTicketDetails();
    }
    if (ticket?.userId) {
      fetchAvatar(ticket.userId);
    }
    fetchComments();
  }, [ticketId, ticket, userId]);

  const fetchAvatar = (userId) => {
    if (!userId) {
      console.log('fetchAvatar: userId is null');
      return;
    }
    axios.get(`${BASE_URL}/api/attachments/download/avatar/user/${userId}`, {
      responseType: 'arraybuffer'
    })
      .then(response => {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setAvatar(imageUrl);
      })
      .catch(error => {
        console.error('Error loading avatar:', error);
      });
  };

  const fetchTicketDetails = async () => {
    try {
      const response = await axiosClient.get(`/api/ticket/${ticketId}`);
      setTicket(response.data.data);
    } catch (error) {
      console.error("Ошибка загрузки деталей темы", error);
    }
  };

  const fetchComments = async () => {
    try {
      console.log('Fetching comments with userId:', userId);
      const url = userId ? `/api/comment/${ticketId}?userId=${userId}` : `/api/comment/${ticketId}`;
      const response = await axiosClient.get(url);
      setComments(response.data);
    } catch (error) {
      console.error("Ошибка загрузки комментариев", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    if (!isAuthenticated) {
      setShowAlert(true);
      setAlertMessage("Пожалуйста авторизуйтесь или зарегистрируйтесь");
      setAlertType("info");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }
    try {
      await axiosClient.post(`/api/comment/${ticketId}`, { userId, content: newComment });
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Ошибка при добавлении комментария", error);
    }
  };

  if (!ticket) return <Typography>Загрузка...</Typography>;

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 4, lg: 4 }} mx={-2} pt={16}>
        <Container maxWidth="false" sx={{ px: 0 }}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={12} lg={12}>
              <TicketDetails ticket={ticket} user={userId} likeCount={ticket.likeCount} disLikeCount={ticket.disLikeCount} avatar={avatar} />
              <Divider sx={{ my: 3 }} />
              <CommentSection ticketId={ticketId} comments={comments} userId={userId} />
             
              {ticket.statusName !== 'Закрыт' && ticket.statusName !== 'Закрыт Администратором' && (
                <>
                  <MKInput
                    fullWidth
                    multiline
                    rows={3}
                    value={newComment}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleAddComment();
                      }
                    }}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Добавить комментарий..."
                  />
                  <MKButton variant="contained" color="info" onClick={handleAddComment} sx={{ mt: 2 }}>
                    Отправить
                  </MKButton>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
      <DefaultFooter content={footerRoutes} />
    </>
  );
};

export default TicketDetailsPage;