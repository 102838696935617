import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import BaseLayout from "layouts/sections/components/BaseLayout";
import View from "layouts/sections/components/View";
import axiosClient from "app/axiosClient";
import { BASE_URL } from "app/api";

function NewsDetailPage() {
  const { id } = useParams(); 
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    async function fetchNewsDetail() {
      try {
        const response = await axiosClient.get(`/api/news/${id}`);
        setNewsItem(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }

    fetchNewsDetail();
  }, [id]);

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  const { title, content, description } = newsItem;

  return (
    <BaseLayout
      title={title}
      breadcrumb={[
        { label: "Home", route: "/" },
        { label: "News", route: "/news" },
      ]}
    >
      <View  height="auto">
        <MKBox
          component="section"
          sx={{
            backgroundColor: "#f8f9fa", 
            borderRadius: "12px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "2rem", 
          }}
        >
    

          <MKBox mb={4}>
            <MKTypography variant="h3" fontWeight="bold" gutterBottom>
              {title}
            </MKTypography>

            <MKTypography variant="body1" color="textSecondary" paragraph>
              {description}
            </MKTypography>

            <MKTypography variant="body2" mb={3} color="textSecondary" paragraph>
              {content}
            </MKTypography>

          <MKBox
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "center", 
              alignItems: "center", 
              marginBottom: "2rem", 
            }}
          >
            <img
              src={`${BASE_URL}/api/attachments/download/news/${id}`}
              alt={title}
              style={{
                width: "100%",
                maxWidth: "800px",
                borderRadius: "10px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                objectFit: "cover", 
              }}
            />
          </MKBox>
          </MKBox>
        </MKBox>
      </View>
    </BaseLayout>
  );
}

export default NewsDetailPage;
