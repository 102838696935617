import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "app/api";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";


function SignInBasic() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  async function login() {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/authenticate`, {
        login: username,
        password: password,
      });
  
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("refresh", response.data.refresh);
  
        window.location.href = '/';
        setShowAlert(true);
        setAlertMessage("Приветcтвую!");
        setAlertType("success");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } else {
        setShowAlert(true);
        setAlertMessage("Incorrect Email and Password do not match");
        setAlertType("error");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (err) {
      if (err.response && err.response.data) {
        const errorData = err.response.data.error;
  
        if (err.response.status === 401) {
          setShowAlert(true);
          setAlertMessage("Неправильный логин или пароль");
          setAlertType("error");
        } else if (err.response.status === 423 && errorData) {
          setShowAlert(true);
          setAlertMessage(errorData.details || "Ваш аккаунт заблокирован");
          setAlertType("warning");
        } else {
          setShowAlert(true);
          setAlertMessage(err.response.data.message || "Произошла ошибка");
          setAlertType("error");
        }
  
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      } else {
        setShowAlert(true);
        setAlertMessage("Что-то пошло не так, повторите позже!");
        setAlertType("error");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    }
  }
  
  
  

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Авторизация
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <MKInput
                      type="login"
                      label="Логин"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Пароль"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Запомнить меня
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton type="submit" variant="gradient" color="info" fullWidth>
                      Войти
                    </MKButton>
                  </MKBox>
                  <MKBox display="flex" justifyContent="space-between" mt={2}>
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/register")}
                    >
                      Зарегистрироваться
                    </MKTypography>
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      Забыли пароль?
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
                  <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
                          <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
                            <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
                          </div>
                        </CSSTransition>
    </>
  );
}

export default SignInBasic;
