import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "app/api";
import ConfirmationCodeInput from "../signup/ConfirmationCodeInput";
import { set } from "date-fns";
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

function PasswordReset() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();

  const handleRequestPinCode = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/reset-password-request?email=${email}`);
      if (response.status === 200) {
        setShowAlert(true);
        setAlertMessage("Пин код отправлен на вашу почту, введите пин код");
        setAlertType("success");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);    }
        setStep(2);
      
    } catch (err) {
      setShowAlert(true);
      setAlertMessage(err.response?.data?.message || err.message);
      setAlertType("error");
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/reset-password`,null, {
        params: {
          email,
          pinCode,
          newPassword,
        },
      });
      if (response.status === 200) {
        setShowAlert(true);
        setAlertMessage("Пароль успешно сброшен, пожалуйста авторизуйтесь");
        setAlertType("success");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);    }
        setStep(2);
        navigate("/sign-in");
      
      } catch (err) {
        setShowAlert(true);
        setAlertMessage(err.response?.data?.message || err.message);
        setAlertType("error");
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);    }
  };

  const renderRequestPinCodeForm = () => (
    <MKBox component="form" role="form" onSubmit={handleRequestPinCode}>
          <MKTypography variant="body2" color="text" textAlign="center">
          Мы отправим код подтверждения на почту чтобы убедиться что это вы <strong>{email}</strong>
        </MKTypography>
      <MKBox mb={2} mt={2}>
        <MKInput
          type="email"
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </MKBox>
      <MKBox mt={4} mb={1}>
        <MKButton type="submit" variant="gradient" color="info" fullWidth>
          Отправить
        </MKButton>
      </MKBox>
    </MKBox>
  );

  const renderResetPasswordForm = () => (
    <MKBox component="form" role="form" onSubmit={handleResetPassword}>
      <MKBox mb={3}>
        <MKTypography variant="body2" color="text" textAlign="center">
          We sent a PIN code to <strong>{email}</strong>
        </MKTypography>
      </MKBox>
      <MKBox mb={3}>
        <MKTypography variant="body2" color="text" textAlign="center">
          Enter PIN code and new password
        </MKTypography>
      </MKBox>
      <MKBox mb={2}>
      <ConfirmationCodeInput confirmationCode={pinCode} setConfirmationCode={setPinCode} />
      </MKBox>
      <MKBox mb={2}>
        <MKInput
          type="password"
          label="New Password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </MKBox>
      <MKBox mt={4} mb={1}>
        <MKButton type="submit" variant="gradient" color="info" fullWidth>
          Reset Password
        </MKButton>
      </MKBox>
    </MKBox>
  );

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  {step === 1 ? "Сбросить пароль" : "Сбросить"}
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                {step === 1 ? renderRequestPinCodeForm() : renderResetPasswordForm()}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
                          <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
                            <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
                          </div>
                        </CSSTransition>
    </>
  );
}

export default PasswordReset;
