import React, { useState, useEffect, useRef } from "react";
import axiosClient from "app/axiosClient";
import { Box, Typography, Card, Grid, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import routes from "common/routes";
import MKBox from "components/MKBox";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from 'react-router-dom';
import MKAlert from "components/MKAlert";
import { CSSTransition } from 'react-transition-group';
import { BASE_URL } from "app/api";
import defaultImage from "../../assets/images/default-image.png";

const TicketEdit = ({ onTicketEditSuccess }) => {
  const location = useLocation();
  const ticket = location.state?.ticket || {};
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(ticket.categoryTypeId || "");
  const [selectedType, setSelectedType] = useState(ticket.ticketTypeId || "");
  const [title, setTitle] = useState(ticket.title || "");
  const [userId, setUserId] = useState(null);
  const [description, setDescription] = useState(ticket.description || "");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(ticket.imageName || "");
  const [filePreview, setFilePreview] = useState(`${BASE_URL}/api/attachments/download/tickets/${ticket.id}`);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showAlert, setShowAlert] = useState(false);
  const fileInputRef = useRef(null);
  const [youtubeLink, setYoutubeLink] = useState(ticket.youtubeLink || "");

  useEffect(() => {
    const fetchCategoriesAndTypes = async () => {
      try {
        const response = await axiosClient.get("/api/common-reference/by-type/002");
        const data = response.data.data;
        const parentCategories = data.filter(item => item.parentId === null);
        setCategories(parentCategories);
        setTypes(data.filter(item => item.parentId !== null));
      } catch (error) {
        console.error("Ошибка при загрузке категорий и типов:", error);
      }
    };

    fetchCategoriesAndTypes();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) return;
    try {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
    } catch (error) {
      console.error("Ошибка декодирования токена:", error);
    }
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleResetFile = () => {
    setFile(null);
    setFileName(ticket.imageName || "");
    setFilePreview(ticket.imageUrl ? `${BASE_URL}/api/attachments/download/tickets/${ticket.id}` : null);
    fileInputRef.current.value = null;
  };

  const updatePhoto = async (ticketId) => {
    if (!file) return;
    setIsSubmitting(true);

    const formData = new FormData();
    const attachmentDto = {
      type: "TICKET_IMAGE",
      originName: file.name,
      description: "Ticket related image",
      ticketsId: ticketId,
    };

    formData.append("dto", JSON.stringify(attachmentDto));
    formData.append("file", file);

    try {
      await axiosClient.post("/api/attachments/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFile(null);
      setFileName(file.name);
      setFilePreview(`${BASE_URL}/api/attachments/download/tickets/${ticketId}`);
    } catch (error) {
      console.error("Ошибка обновления фото:", error);
      setAlertMessage("Ошибка при загрузке фото!");
      setAlertType("error");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!ticket.id) {
      setAlertMessage("Ошибка: ID тикета отсутствует!");
      setAlertType("error");
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
      return;
    }

    const updatedTicket = {
      categoryTypeId: selectedCategory,
      ticketTypeId: selectedType,
      title,
      description,
      youtubeLink,
      userId,
      is_archived: ticket.is_archived || false,
    };

    try {
      setIsSubmitting(true);
      const response = await axiosClient.put(`/api/ticket/${ticket.id}`, updatedTicket);
      if (response.status === 200) {
        await updatePhoto(ticket.id);
        if (onTicketEditSuccess) onTicketEditSuccess();
        setAlertMessage('Тикет успешно обновлен!');
        setAlertType('success');
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate('/tickets');
        }, 3000);
      }
    } catch (error) {
      setAlertMessage('Ошибка при обновлении тикета, попробуйте снова!');
      setAlertType('error');
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Box px={3} py={5}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <Card elevation={3} sx={{ p: 3 }}>
                <Typography variant="h4" mb={3} align="center">
                  Редактировать Тикет
                </Typography>
                <form onSubmit={handleSubmit}>
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Категория</InputLabel>
                    <Select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      label="Категория"
                      sx={{ height: "48px", fontSize: "1rem" }}
                    >
                      <MenuItem value="" disabled>Выберите категорию</MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="normal" required disabled={!selectedCategory}>
                    <InputLabel>Тип</InputLabel>
                    <Select
                      value={selectedType}
                      onChange={(e) => setSelectedType(e.target.value)}
                      label="Тип"
                      sx={{ height: "48px", fontSize: "1rem" }}
                    >
                      <MenuItem value="" disabled>Выберите тип</MenuItem>
                      {types
                        .filter((type) => type.parentId === parseInt(selectedCategory))
                        .map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <MKInput
                    label="Заголовок"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    margin="normal"
                  />
                  <MKInput
                    label="Описание"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    margin="normal"
                  />
                    <MKInput
                                      label="Ссылка на YouTube"
                                      fullWidth
                                      rows={4}
                                      value={youtubeLink}
                                      onChange={(e) => setYoutubeLink(e.target.value)}
                                      required
                                      margin="normal"
                                    />
                  <Box mb={2} textAlign="center">
                    <label htmlFor="file-input">
                      <MKButton 
                        variant="contained" 
                        color="info" 
                        onClick={() => fileInputRef.current.click()}
                      >
                        Выбрать фотографию
                      </MKButton>
                      <input
                        id="file-input"
                        ref={fileInputRef}
                        type="file"
                        name="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                      />
                    </label>
                    <Box mt={2}>
                      {fileName && (
                        <Typography>Выбранный файл: {fileName}</Typography>
                      )}
                      <Box mt={1} sx={{ border: "1px dashed #ccc", padding: "10px", minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img 
                          src={filePreview || defaultImage} 
                          alt="Предпросмотр" 
                          style={{ maxWidth: '200px', maxHeight: '200px', objectFit: "cover" }} 
                          onError={(e) => (e.target.src = defaultImage)}
                        />
                      </Box>
                      {(fileName || filePreview) && (
                        <Box mt={1}>
                          <MKButton
                            variant="outlined"
                            color="error"
                            onClick={handleResetFile}
                          >
                            Сбросить фото
                          </MKButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box mt={2} textAlign="center">
                    <MKButton type="submit" variant="contained" color="info" fullWidth disabled={isSubmitting || !ticket.id}>
                      {isSubmitting ? "Сохранение..." : "Сохранить изменения"}
                    </MKButton>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </>
  );
};

export default TicketEdit;