import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import Carousel, { Modal, ModalGateway } from "react-images";
import { isUserAuthenticated } from "common/auth";
import axiosClient from "app/axiosClient";
import { BASE_URL } from "app/api";

const isAuthenticated = isUserAuthenticated();

const GalleryDisplay = () => {
  const [photos, setPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const fetchPhotos = async () => {
    try {
      const response = await axiosClient.get(`/api/getAllPhotos`);
      setPhotos(response.data);
    } catch (error) {
      console.error("Ошибка при загрузке фотографий:", error);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const handleDeletePhoto = async (photoId) => {
    try {
      await axiosClient.delete(`/api/deletePhoto/${photoId}`);
      fetchPhotos();
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  return (
    <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2}>
      <Container sx={{ py: 6 }}>
        <div>
          <h1>Галерея</h1>
        </div>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={20} sx={{ ml: "auto" }}>
            <MKBox
              component={Grid}
              container
              spacing={3}
              sx={{
                mt: { xs: 0, md: 3 },
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {photos.map((photo, index) => (
                <Grid item xs={12} md={6} key={photo.id} style={{ flex: "0 0 50%" }}>
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${BASE_URL}/api/photos/${photo.id}`}
                      alt="Загруженное фото"
                      style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }}
                      onClick={() => openLightbox(index)}
                    />

                  {isAuthenticated && localStorage.getItem("role") === "ADMIN" && (
                    <button
                      onClick={() => handleDeletePhoto(photo.id)}
                      style={{
                        position: "absolute",
                        top: "0.5rem",
                        right: "0.5rem",
                        background: "none",
                        border: "none",
                        color: "red",
                        cursor: "pointer",
                      }}
                    >
                      Удалить
                    </button>
                  )}
                  </div>
                </Grid>
              ))}
            </MKBox>
          </Grid>
        </Grid>
      </Container>

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((photo) => ({
                src: `https://mayakbishkek.kg/api/photos/${photo.id}`,
                caption: photo.fileName,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </MKBox>
  );
};

export default GalleryDisplay;
