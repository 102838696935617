import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Stack } from '@mui/material';

const PaginationContext = React.createContext();

const MKPagination = ({ currentPage, totalPages, onPageChange, variant, color, size, placement, children, ...rest }) => {
  const context = useContext(PaginationContext);

  const paginationProps = useMemo(() => ({ variant, color, size }), [variant, color, size]);

  let placementValue = 'flex-end';
  if (placement === 'left') {
    placementValue = 'flex-start';
  } else if (placement === 'center') {
    placementValue = 'center';
  }

  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <PaginationContext.Provider value={paginationProps}>
      <Stack spacing={2} alignItems={placementValue} my={4}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color={color || context?.color || 'dark'}
          variant={variant || context?.variant || 'contained'}
          shape="circular"
          {...rest}
        />
        {children}
      </Stack>
    </PaginationContext.Provider>
  );
};

MKPagination.defaultProps = {
  variant: 'text',
  color: 'info',
  size: 'large',
  placement: 'center',
};

// Проверка типов пропсов
MKPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['outlined', 'text', 'contained']),
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  placement: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node,
};

export default MKPagination;
