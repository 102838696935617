import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ExampleCard from "../components/ExampleCard";
import axiosClient from "app/axiosClient";
import FilterForm from "../../FilterForm";
import MKPagination from "components/Pagination/MKPagination";
import { BASE_URL } from "app/api";

function NewsSection() {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = localStorage.getItem("newsPage");
    return savedPage ? parseInt(savedPage, 10) : 0;
  });
  const [itemsPerPage] = useState(6);
  const [filterValues, setFilterValues] = useState(() => {
    const savedFilters = localStorage.getItem("newsFilters");
    return savedFilters ? JSON.parse(savedFilters) : { title: "", description: "" };
  });
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const currentUserRole = localStorage.getItem("role");
  const isAdmin = currentUserRole === "ADMIN" || currentUserRole === "EMPLOYEE";

  useEffect(() => {
    async function getNewsData() {
      try {
        const response = await axiosClient.get('/api/news/by-filters', {
          params: {
            title: filterValues.title || null,
            description: filterValues.description || null,
            page: page,
            size: itemsPerPage,
            sort: "desc",
          },
        });

        const data = response.data.data;
        setTotalPages(data.totalPages || 1);

        const newsData = data.content.map(newsItem => ({
          id: newsItem.id,
          image: `${BASE_URL}/api/attachments/download/news/${newsItem.id}`,
          title: newsItem.title,
          route: `/news/${newsItem.id}`,
          content: newsItem.content,
          description: newsItem.description,
          imageName: newsItem.imageName || `NEWS_IMAGE№${newsItem.id}`,
          isPublished: newsItem.isPublished,
          isArchived: newsItem.isArchived,
        }));

        setNews(newsData);
      } catch (error) {
        console.error("Ошибка при получении новостей:", error);
        setNews([]);
        setTotalPages(1);
      }
    }

    getNewsData();
  }, [filterValues, page, itemsPerPage]);

  useEffect(() => {
    localStorage.setItem("newsFilters", JSON.stringify(filterValues));
    localStorage.setItem("newsPage", page.toString());
  }, [filterValues, page]);

  const handleFilterChange = (newFilters) => {
    setFilterValues(newFilters);
    setPage(0);
  };

  const handleEditClick = (newsItem) => {
    navigate('/news-edit', { state: { news: newsItem } });
  };

  const renderData = news.map(({ id, image, title, content, route, description, imageName, isPublished, isArchived }) => (
    <Grid item xs={12} md={4} sx={{ mb: 2 }} key={id}>
      <Link
        to={{
          pathname: route,
          state: { image, title, content, description },
        }}
        style={{
          display: "block",
          width: "100%",
          height: "100%",
        }}
      >
        <ExampleCard image={image} title={title} content={content} />
      </Link>
      {isAdmin && (
        <MKButton
          variant="contained"
          color="info"
          onClick={() => handleEditClick({ id, image, title, content, description, imageName, isPublished, isArchived })}
          sx={{ mt: -5, width: "100%" }}
        >
          Редактировать
        </MKButton>
      )}
    </Grid>
  ));

  return (
    <MKBox
      component="section"
      variant="gradient"
      position="relative"
      py={6}
      px={0}
      mx={0}
      pt={16}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container>
        <MKTypography variant="h2" fontWeight="bold">
          Новости
        </MKTypography>
      </Container>

      <Container sx={{ mt: 6 }}>
        <FilterForm
          filterOptions={[
            { label: "Наименование", id: "title", type: "text", options: [] },
            { label: "Описание", id: "description", type: "text", options: [] },
          ]}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          onFilterChange={handleFilterChange}
        />
        <Grid container spacing={3}>
          {renderData.length > 0 ? renderData : (
            <MKTypography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
              Новости не найдены
            </MKTypography>
          )}
        </Grid>
        <MKPagination
          currentPage={page + 1}
          totalPages={totalPages}
          onPageChange={(newPage) => setPage(newPage - 1)}
        />
      </Container>
    </MKBox>
  );
}

export default NewsSection;