import  { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AutoSignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    console.log(localStorage.getItem("token"));
    window.location.href = '/';
  }, [navigate]);

  return null; 
}

export default AutoSignOut;
