import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ExampleCard({ image, title, count, pro, ...rest }) {
  const imageTemplate = (
    <MKBox
      bgColor="white"
      borderRadius="xl"
      shadow="lg"
      minHeight="10rem"
      sx={{
        width: "100%",
        height: "200px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
        transformOrigin: "50% 0",
        backfaceVisibility: "hidden",
        willChange: "transform, box-shadow",
        transition: "transform 200ms ease-out",
        "&:hover": {
          transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
        },
      }}
      {...rest}
    >
      <MKBox
        component="img"
        src={image}
        alt={title}
        width="100%"
        height="100%"
        sx={{ objectFit: "cover" }}
        opacity={pro ? 0.6 : 1}
      />
    </MKBox>
  );

  return (
    <MKBox position="relative">
      {pro ? (
        <Tooltip title="Pro Element" placement="top">
          {imageTemplate}
        </Tooltip>
      ) : (
        imageTemplate
      )}
      {title || count > 0 ? (
        <MKBox mt={1} ml={1} lineHeight={1}>
          {title && (
            <MKTypography
              variant="h6"
              fontWeight="bold"
              sx={{
                whiteSpace: "nowrap", // Текст в одну строку
                overflow: "hidden",   // Скрываем переполнение
                textOverflow: "ellipsis", // Добавляем многоточие
                maxWidth: "100%",     // Ограничиваем ширину контейнером
              }}
            >
              {title}
            </MKTypography>
          )}
          {count > 0 && (
            <MKTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {count} {count === 1 ? "Example" : "Examples"}
            </MKTypography>
          )}
        </MKBox>
      ) : null}
    </MKBox>
  );
}

// Setting default props for the ExampleCard
ExampleCard.defaultProps = {
  name: "",
  count: 0,
  pro: false,
};

// Typechecking props for the ExampleCard
ExampleCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  count: PropTypes.number,
  pro: PropTypes.bool,
};

export default ExampleCard;