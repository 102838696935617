/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import RotatingCard from "examples/Cards/RotatingCard";
// import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import AlkoIcon from "assets/images/icons/zavis/Алкоголизм.png";
import NarkoIcon from "assets/images/icons/zavis/Наркомания.png";
import IgroIcon from "assets/images/icons/zavis/Игромания.png";
import ToxicIcon from "assets/images/icons/zavis/Токсикомания.png";
// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";
const iconStyle = {
  width: "50px", // Укажите желаемую ширину
  height: "50px", // Укажите желаемую высоту
};
function InfoSection() {
  return (
 <MKBox component="section"
      variant="gradient"
      position="relative"
      py={16}
      px={{ xs: 2, lg: 0 }}
      mx={-2}>     
            <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={12} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <DefaultInfoCard
                icon={<img src={AlkoIcon} style={iconStyle} />}
                title="Информация об Алкоголизме"
                description={
                  // prettier-ignore
                  <div  >
                    <p>
                      <b>Алкоголизм</b> – это хроническое заболевание, характеризующееся зависимостью организма от употребления алкоголя,
                      заболевание способно длиться довольно длительное время и привести к тяжелым последствиям в организме.
                      <br />
                      <br />
                      Болезнь прогрессирует с каждым употреблением и при этом человек становится психологически и физически
                      зависимым от употребления. Болезнь сопровождается стойкими психическими и соматическими расстройствами.
                      <br />
                      <br />
                      Длительное возбуждение нервной системы при воздержании от употребления, когда зависимость уже сформирована,
                      приводит к повреждению и разрушению клеток головного мозга, галлюцинациям, психозам, эпилептическим припадкам.
                      <br />
                      <br />
                      Социальной проблемой является детский и подростковый алкоголизм. Дети и подростки начинают пробовать алкоголь по
                      разным причинам: подражая взрослым, желая казаться старше и просто из-за любопытства. Под действием алкоголя
                      происходит большая часть ДТП, попыток суицида и другого рода проблем в жизни зависимого.
                      <br />
                      <br />
                      Зависимые, как правило, отрицают наличие заболевания. Если у человека есть проблема в отношениях с близкими, трудности на работе из-за спиртного и невозможность контролировать количество употребляемого алкоголя – это повод признать зависимость и начать лечение.
                      <br />
                      <br />
                      Уже в XIX веке считалось, что с повышением благосостояния населения начинает расти и алкоголизм. ВОЗ считает, что тенденция заболевания алкоголизмом в мире постоянно растёт. Если в 2000 году людей, больных алкоголизмом, в мире было около 140 миллионов, то в 2010 году – уже около 208 миллионов: при такой тенденции к 2050 году число людей, страдающих зависимостью, в мире может превысить 500 миллионов человек из 9,5 млрд. населения. 
                    </p>
                    <br />
                    <br />
                    <p>
                    <b>Симптомы и признаки Алкоголизма</b>
                    <br />
                    <br />
                    <ul style={{ marginLeft: "30px" }} >
                      <li>
                        <b>Сильная тяга</b> – зависимый употребляет невзирая на отрицательные последствия и отношения с близкими, отказывается от любых занятий и ищет причины в пользу употребления алкоголя;
                      </li>
                      <br />
                      <li>
                        <b>Бесконтрольность</b> – невозможность контролировать количество и время приема алкоголя;
                      </li>
                      <br />
                      <li>
                        <b>Увеличение употребления</b> – необходимость употреблять большее количество алкоголя, для достижения опьянения. Отсутствует толерантность и рвотный рефлекс, когда зависимый употребил слишком много;
                      </li>
                      <br />
                      <li>
                        <b>Симптомы отмены</b> – при прекращении употребления у зависимого проявляются следующие симптомы:
                        <ul style={{ marginLeft: "30px" }}>
                          <li>
                            нарушение сна;
                          </li>
                          <li>
                            беспокойство;
                          </li>
                          <li>
                            боль в животе;
                          </li>
                          <li>
                            тревожность;
                          </li>
                          <li>
                            головная боль;
                          </li>
                          <li>
                            повышение давления;
                          </li>
                          <li>
                            дрожь;
                          </li>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <b>Последствия</b> – зависимый со временем становится беспокойным и нервным, начинает испытывать вину и пытается скрыть от близких и родных употребление алкоголя. В крайнем случае зависимый начинает употреблять спиртосодержащие средства, для достижения какого-то эффекта опьянения.
                      </li>
                    </ul>
                    </p>
                    <br />
                    <br />
                    <p>
                      <b>Причины развития Алкоголизма</b>
                    </p>
                    <br />
                    <ul style={{ marginLeft: "30px" }}>
                      <li>Биологическая – генетическая предрасположенность;</li>
                      <li>Нервные и психические болезни раннего детского возраста;</li>
                      <li>Функционирование нервной системы в виде превалирования процессов возбуждения;</li>
                      <li>Социальная среда обитания;</li>
                      <li>Психологически – низкая самооценка, тревожность, неуверенность, неумение справляться со стрессом, только употребление алкоголя приносит удовольствие и вызывает эйфорию;</li>
                    </ul>
                    <br />
                    <p>
                    Дети алкоголиков рискуют повторить путь родителей на 30% больше, чем их здоровые сверстники. С болезненными проявлениями дети знакомятся в слишком раннем возрасте, когда критика еще невозможна, и легко следует проторенным путем. Важно прослеживать состояние и настроение человека, который начинает употреблять алкоголь. Скрытие своих проблем и недостатков может повлиять на начало употребления.
                    </p>
                    <br />

                    <p><b>Методы лечения Алкоголизма</b></p>
                    <br />
                    <p>Зависимому трудно самостоятельно прекратить употребление. Лечение во многом эффективно в том случае, если у зависимого есть желание избавиться от употребления. Лечение должно быть комплексным и может занять немало времени. Рассмотрим с Вами методы лечения:</p>
                    <ul style={{ marginLeft: "30px" }}>
                      <br />
                      <li>Лекарственная терапия, направленная на снятие симптомов при отказе от употребления и выведения из организма продуктов распада этанола;</li>
                      <br />
                      <li>Психологическая помощь профессионалов, в котором ведется подробный отчет и анализ поведений зависимого;</li>
                      <br />
                      <li>Поддержка людей, которые избавились или пытаются бороться с болезнью, - группы Анонимных Алкоголиков;</li>
                      <br />
                      <li>Лечение осложнений, вызванных употреблением;</li>
                      <br />
                      <li>
                        Также существует метод введения в организм (внутривенно или перорально) лекарственных средств, которые взаимодействуют с этанолом.
                        При употреблении алкоголя, у зависимого ухудшается самочувствие и возникает тошнота, рвота, головная боль.
                        Эти симптомы помогают побороть тягу к алкоголю;
                      </li>
                      <br />
                      <li>
                        При помощи <b>Реабилитационных Центров</b>, в которых применяются многие вышеперечисленные методы и созданы комфортные условия для зависимого.
                        РЦ помогут зависимому понять причину употребления алкоголя и помочь справляться с ними.
                        Лечебные мероприятия в РЦ, существующих на сегодня, позволяют больше половине зависимым отказаться от алкоголя навсегда или на длительное время.
                        Важно зависимому дать мотивацию. Человек начинает сам прослеживать моменты употребления, также обращать внимание и ценить многие вещи происходящие вокруг него.
                      </li>
                    </ul>
                    <br />
                    <br />
                  </div>
                }
              />
              <DefaultInfoCard
                icon={<img src={NarkoIcon} style={iconStyle} />}
                title="Информация о Наркомании"
                // prettier-ignore
                description={
                  <div>
                    <p>
                      <b>Наркомания</b> – это болезненное влечение или пристрастие к наркотическим веществам, употребляемых с целью добиться одурманивающего состояния или снятия различного рода боли. Это страшная и трудноизлечимая болезнь. Болезнь выражается стойкой психической и физической зависимостью от употребления наркотиков.
                    </p>
                    <br />
                    <p>Это заболевание, сопровождается тотальным разрушением «Я» личности, физического, психического и нравственного здоровья. Это значит, что человек, принимающий наркотики, постепенно уничтожает свои нравственные качества; становится физически неполноценным; теряет друзей и семью; не может пробрести профессию или забывает имеющуюся и в конце концов, полностью разрушает свой организм. Велик социальный вред, наносимый наркоманией обществу, так как разрушаются семьи, дети становятся ненужными своим родителям. </p>
                    <br />
                    <p>Зависимый страдает тяжелым заболеванием, чья жизнь целиком зависит от употребления. Наркотики способны вызывать состояние опьянения, привыкания и зависимости. Наркоманы редко переживают 30-летний возраст.</p>
                    <p>К наркотикам относятся вещества, которые способны вызывать нарушения в работе головного мозга, вследствие чего изменяется мироощущение человека, его нравственные установки, эмоции приобретают излишне выраженный и немотивированный характер (от эйфории до тяжелейшей депрессии с попытками суицидального поведения, которые возникают под влиянием вещества), психическую и физическую зависимость (потребность снова и снова использовать это вещество), причинять существенный вред психическому и физическому здоровью человека, его генетическому материалу (половым клеткам).</p>
                    <br />
                    <p>Наркомания является разновидностью деструктивного поведения, при котором человек, стремясь уйти от окружающей его реальности, употребляет психотропные вещества. Употребление наркотиков приводит к смерти сотни тысяч людей в год и, что самое ужасное, многие из них – молодые люди в возрасте 15-35 лет.</p>
                    <br />
                    <br />
                    <h3>Симптомы и признаки Наркомании</h3>
                    <br />
                    <p>Основным признаком Наркомании является возникновение абстинентного синдрома, как следствие наличия физической зависимости от конкретного вещества.</p>
                    <br />
                    <b>Внешние признаки:</b>
                    <ul style={{ marginLeft: "30px" }}>
                      <br />
                      <li>бледность кожи;</li>
                      <li>расширенные или суженные зрачки;</li>
                      <li>покрасневшие или мутные глаза;</li>
                      <li>замедленная или очень быстрая речь;</li>
                      <li>сухие потрескавшиеся губы;</li>
                      <li>беспричинное слезотечение;</li>
                      <li>насморк;</li>
                      <li>хронический кашель;</li>
                      <li>потеря аппетита;</li>
                      <li>похудание;</li>
                      <li>нарушение координации движения;</li>
                    </ul>
                    <br />
                    <b>Очевидные признаки:</b>
                    <br />
                    <ul style={{ marginLeft: "30px" }}>
                      <br />
                      <li>следы от уколов на руках в области локтевых сгибов, на кистях;</li>
                      <li>шприцы, иглы;</li>
                      <li>таблетки, капсулы, маленькие пакетики с порошком или травяным содержимым;</li>
                      <li>фольга, пузырьки из-под лекарств, пипетки со следами нагара;</li>
                      <li>непонятные запахи от одежды.</li>
                    </ul>
                    <br />
                    <h3>Причины развития Наркомании</h3>
                    <ul style={{ marginLeft: "30px" }}>
                    <br />
                      <li>Социальная среда обитания;</li>
                      <li>Любопытство, желание «просто попробовать»</li>
                      <li>Желание сделать свою жизнь интересной и наполненной или неосознанное желание убежать от сложностей жизни (или осознанное);</li>
                    </ul>
                    <br />
                    <b>Есть два пути после первых опытов:</b>
                    <br />
                    <ul style={{ marginLeft: "30px" }}>
                    <br />
                      <li>Полностью прекратить употребление (около 50% зависимых поступают именно так);</li>
                      <li>Продолжать употребление, что неминуемо ведет к переходу на следующий этап развития зависимости.</li>
                    </ul>
                    <br />
                    <b>Начинает нравиться («розовый» период употребления):</b>
                    <ul style={{ marginLeft: "30px" }}>
                      <br />
                      <li>Осознанное желание получать удовольствие при помощи наркотиков;</li>
                      <li>Планирование употребления;</li>
                      <li>Поиск разумных оправданий употребления;</li>
                      <li>Поиск «подходящей» компании;</li>
                      <li>Наркотики становятся необходимым атрибутом веселья и отдыха;</li>
                    </ul>
                    <br />
                    <b>Возникают проблемы (период «отрицания»):</b>
                    <ul style={{ marginLeft: "30px" }}>
                    <br />
                      <li>Проблемы со здоровьем (похмелье, ломка, неприятные ощущения после употребления, инфекционные заболевания);</li>
                      <li>Скандалы в семье;</li>
                      <li>Финансовые трудности (долги, продажа вещей из дома, постоянный поиск денег);</li>
                      <li>Основной круг общения – те, кто употребляет наркотики;</li>
                    </ul>
                    <br />
                    <b>Это становится целью («дно»):</b>
                    <ul style={{ marginLeft: "30px" }}>
                      <br />
                      <li>Употребление ради употребления;</li>
                      <li>Постоянная потребность в наркотиках;</li>
                      <li>Использование самых крайних мер в поисках дозы;</li>
                      <li>Апатия и нежелание жить, утрата смысла жизни;</li>
                      <li>Попытки самоубийства;</li>
                      <li>Серьезные проблемы со здоровьем, возникновение хронических заболеваний;</li>
                    </ul>
                    <br />
                    <p>
                      На данном этапе наблюдаются глубокие физиологические изменения, организм находится в полной зависимости от наркотиков. Употребление веществ становится необходимым для существования. Мозг перестает самостоятельно регулировать нейрохимический баланс и не может воспринимать окружающий мир без очередной дозы. Зависимый начинает отрицать свою болезнь и может утверждать, что он сам может остановиться. 
                    <br />
                    <br />

                    <p>Если наркоман на этой стадии не прекращает употребления - он умирает.</p>
                    </p>
                    <br /> 
                    <br /> 
                    <h3>Методы лечения Наркомании</h3>
                    <br /> 
                    <ul style={{ marginLeft: "30px" }}>
                      <li>Лекарственная терапия сможет очистить организм от накопившихся ядов;</li>
                      <li>Снятие абстинентного синдрома;</li>
                      <li>Гипноз;</li>
                      <li>Поддержка людей, которые избавились или пытаются бороться с болезнью, - группы Анонимных Наркоманов;</li>
                      <li>Психотерапевтическое и психологическое лечение;</li>
                      <li>При помощи <b>Реабилитационных Центров</b>, в которых применяются вышеперечисленные методы. В комфортных условиях содержания зависимых. Продолжительность связана с состоянием и настроем зависимого. Комплексное лечение можно считаться самым эффективным методом лечения наркомании. Физическую зависимость можно устранить за две – три недели, а психологическая может длиться месяцами. Важна также реабилитация вне клиники, которая может длиться годами.</li>
                    </ul>

                  </div>
                }
              />
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <DefaultInfoCard
                icon={<img src={IgroIcon} style={iconStyle} />}
                title="Информация об Игромании"
                // prettier-ignore
                description={
                  <div>
                    <p>
                    <b>Игромания</b> – это болезнь в основе патогенеза желание человека справиться со стрессовой ситуацией, уйти от реальности и развлечься. Люди склонные к зависимости оказываются в ловушке после получения адреналина, дофамина и других гормонов, дающие сильные ощущения после выигрыша денежных средств. Желание испытывать это чувство вновь и вновь, человек возвращается в игру. Нейрогормоны вводят в состояние, похожее на транс, при котором зависимый не отдает отчет в своих действиях. 
                    </p>
                    <br />
                    <p>
                    Игромания является формой поведенческой зависимости, которая связана с участием в азартных играх. Зависимый рискует имеющимися материальными ценностями ради мнимого выигрыша, который является в большинстве случаев случайностью.
                    </p>
                    <br />
                    <p>
                      Среди специалистов долгое время оставался спорным вопросом зарождения механизма игромании. Многие относили это заболевание к группе расстройств импульсивного контроля, другие к обсессивно-компульсивным расстройствам и так же к аддикции. В итоге игроманию включили в перечень нехимических зависимостей.
                    </p>
                    <br />
                    <p>
                      Зависимый не задумывается, как можно вылечиться от игромании, и считает, что его обвиняют необоснованно. Перестает уделять внимание своему внешнему виду, не интересуется тем, что происходит в мире. Возможен распад семьи, увольнение с работы, совершение противозаконных поступков.
                    </p>
                    <br />
                    <p>
                      Если зависимости не уделять внимание, ситуация становится критической. Игроман утрачивает свой социальный статус, перестает общаться с друзьями. Деградация личности, появляются денежные долги.
                    </p>
                    <br />
                    <p>
                      Родственникам игромана стоит незамедлительно обратиться к врачу в специализированную клинику лечения зависимостей. Самостоятельно помочь ему избавиться от зависимости, как показывает практика, не получится. Родственникам стоит понять следующее. 
                    </p>
                    <br />
                    <p>
                      Игромания – это не вопрос отсутствия силы воли и не проявления слабости. Это заболевание, требующее квалифицированной медицинской помощи, которое нельзя пускать на самотек. Финансовый урон, который наносит зависимый, зачастую кратно превышает стоимость своевременного проведения лечения в профильной клинике. 
                    </p>
                    <br />
                    <h3>Симптомы и признаки Игромании</h3>
                    <ul style={{ marginLeft: '30px' }}>
                    <br />
                      <li>Невротическая форма – когда игромания сочетается с антисоциальным поведением (агрессией) и проявляется склонностью к обману ради наживы и удовольствий;</li>
                      <br />
                      <li>Отсутствие желания отвлечься от включенной игры, агрессивное поведение при вынужденном отстранении, эмоциональный подъем при возобновлении игры;</li>
                      <br />
                      <li>Субкультурная форма – когда мотивы человека кроются в желании приобрести социально значимый статус, однако, со временем он начинает зависеть от игр и не может преодолеть тягу к ним;</li>
                      <br />
                      <li>Импульсивная форма – характеризуется снижением уровня самоконтроля;</li>
                      <br />
                      <li>Симптоматическая форма – проявляется на фоне выраженного психического расстройства, к примеру, депрессии или шизофрении;</li>
                      <br />
                      <li>Эмоциональная нестабильность. У игромана наблюдаются частые перепады настроения, сменяемые то вспышками гнева, то депрессивными, суицидальными мыслями;</li>
                      <br />
                      <li>Изменчивость в поведении. Человек перестает следить за своим внешним видом, зацикливается на своих мыслях, отчуждается от родных и друзей, у него появляются новые знакомства, а все свои средства он тратит только на игры;</li>
                      <br />
                      <li>Наличие финансовых проблем. Больной теряет контроль над деньгами и временем, нередко крадет ценные вещи, вгоняет себя в долги, берет кредиты и все ради того, чтобы получить надежду на призрачный выигрыш;</li>
                    </ul>
                    <br />
                    <h3>Причины развития Игромании</h3>
                    <ul style={{ marginLeft: '30px' }}>
                    <br />
                      <li>Несформированная психика (подростковый возраст);</li>
                      <li>Сексуальная неудовлетворенность;</li>
                      <li>Нехватка/отсутствие ярких моментов в жизни;</li>
                      <li>Длительный стресс, который человек пытается «выплеснуть» в игре;</li>
                      <li>Жажда острых ощущений;</li>
                      <li>Криминальная деятельность;</li>
                      <li>Экспрессивность – эмоциональная дезориентация мышления, спонтанная вера в удачу;</li>
                      <li>Неустойчивость самоконтроля – конфликтность представлений о себе; неадекватность самооценки (независимо от возрастной группы);</li>
                      <li>Чувство одиночества;</li>
                      <li>Склонность к другим зависимостям, например, наркотическую или алкогольную;</li>
                      <li>Напряженность - активная неудовлетворенность стремлений.</li>
                    </ul>
                    <br />
                    <h3>Методы лечения Игромании</h3>
                    <br />
                    <p>У взрослых лечение осложняется тем, что большинство не признают зависимость. На начальном этапе важно, чтобы игроман осознал, что его пристрастие болезненно. Эффективные лечения игромании:</p>
                    <ul style={{ marginLeft: '30px' }}>
                    <br />
                      <li>Прием лекарственных препаратов, которые направленны на стабилизацию настроения, и антидепрессанты подавляют сопутствующие проблемы – синдром навязчивых состояний, депрессию. Полностью вылечить зависимость препаратами нельзя. Важна еще и психотерапия;</li>
                      <br />
                      <li>Лечение игромании эффективно, если применяется когнитивная поведенческая терапия. Специалист обучает зависимого навыкам подавления тяги к игре, концентрируя внимание на поступках. Выявленные нездоровые и негативные установки подменяются позитивными, рациональными;</li>
                      <br />
                      <li>В ходе встреч с группами «Анонимных Игроков», проводятся беседы как избавиться от игромании. Участники делятся эмоциональными переживаниями, проблемами и успехами. Анализируя как изменяется их образ жизни по мере отказа от участия в азартных играх. Делятся опытом в борьбе с зависимостью;</li>
                      <br />
                      <li>Мотивационное интервью причем как отдельно, так и совместно с другими методами. В основе лежит нормативная обратная связь, которая помогает игроману проанализировать свою зависимость и изменить ситуацию. Этот метод позволяет сократить частоту игр и снизить степень тяжести расстройства;</li>
                      <br />
                      <li>Обращение в специальные <b>Реабилитационные Центры</b>, которые применяют вышеперечисленные методы лечения. Игромания это такая же зависимость, как и алкоголизм и наркомания. Необходимо найти подход к зависимому и открыть глаза на те вещи, которые стали ему безразличны. Прохождение терапии по зависимости и выполнение заданий, для раскрытия причин Игромании.</li>
                    </ul>
                    <br />
                    <p>Сильная зависимость от игр делает практически невозможной самостоятельное излечивание от нее. Больной не всегда способен осознать свою проблему и обратиться к врачу, поэтому его близкие должны взять в свои руки вопрос обращения к специалисту.</p>
                    <br />
                    <p>
                      Обращаться к специализированному врачу в клинику зависимостей нужно уже при первых признаках. Только в зародыше болезни можно на корню убить дальнейшее его развитие и избежать негативных осложнений игромании. При это не нужно настаивать, повышать голос или конфликтовать. Разговаривать с больным лучше всего в благоприятной обстановке, в момент, когда он ничем не раздражен и не обеспокоен.
                    </p>
                    <br />
                    <p>
                      К каждому пациенту свой подход к лечению, исходя из обследования, доминирующих симптомов. Пройдя полный курс лечения зависимости, человек может вновь начать играть, если поддерживает отношения с игроками. При возникновении сильного желания сыграть рекомендуется незамедлительно посетить психотерапевта, чтобы исключить возврат к зависимости.
                    </p>
                  </div>
                }
              />
              <div style={{ marginTop: "50px" }}>
                <DefaultInfoCard
                  icon={<img src={ToxicIcon} style={iconStyle} />}
                  title="Информация о Токсикомании"
                  // prettier-ignore
                  description={
                    <div>
                      <p>
                        <b>Токсикомания</b> — этo употребление химическими, биологическими и лекарственными препаратами, не входящих в перечень наркотических. Токсикомания и наркомания имеют общие понятия - клей, лак, топливо и другая химия могут вызвать привыкание и зависимость также как наркотики.
                      </p>
                      <br />
                      <p>
                        При токсикомании человек употребляет химические соединения c галлюциногенным и опьяняющим действием. Способ введения их в организм - через легкие, путем вдыхания паров. Таким образом, токсическое соединение напрямую воздействует на головной мозг отравляя его, в результате чего и наступает опьянение.
                      </p>
                      <br />
                      <p>
                        Токсикомании c использованием средств бытовой химии рассматриваются преимущественно как проблема детей и подростков, средний возраст пациентов, страдающих токсикоманией, составляет 12-14 лет. У взрослых людей токсикомании, связанные с употреблением бытовых и промышленных химических препаратов, встречаются редко. В числе средств, употребляемых при токсикомании - ацетон, растворители для нитрокрасок, бензин, горючее для авиамоделей, лак для обуви, пятновыводители и клеи.
                      </p>
                      <br />
                      <p>
                        Наркомания правомерно в тех случаях, когда болезненное пристрастие развивается к веществам, признанным наркотическими и находящимися под особым контролем государства. В тех случаях, когда патологическое влечение развилось к веществам, не приведенных в списке наркотиков, болезнь является токсикоманией.
                      </p>
                      <br />
                      <p>
                        Токсические вещества вызывают хроническое отравление организма, развивается психическая и физическая зависимость. С точки зрения биологических процессов — токсикомания ничем не отличается от наркомании. Токсикомания бензином, например, — её последствия так же опасны, как и героиновая.
                      </p>
                      <br />
                      <p>
                        При отсутствии лечения зависимость приводит к разрушению личности, психическим расстройствам и дисфункции внутренних органов. В головном мозге человека происходят необратимые изменения. Подросток начинает отставать в развитии, возникает слабоумие, что приводит к инвалидизации.
                      </p>
                      <br />
                      <p>
                        Первые сообщения о появлении токсикомании были зафиксированы в 1960 году в США - американские подростки вдыхали разные бытовые химические вещества (растворители, лаки, бензин, краски и др.), доводя себя до наркотического опьянения.
                      </p>
                      <br />
                      <h3>Симптомы и признаки Токсикомании</h3>
                      <br />
                      <ul style={{ marginLeft: '30px' }}>
                        <li>Депрессивный настрой;</li>
                        <li>Тошнота, рвота;</li>
                        <li>Усталость, физическая слабость;</li>
                        <li>Нарушение психической функции;</li>
                        <li>Попытки получить препараты любым способом (воровство);</li>
                        <li>Расширение зрачков;</li>
                        <li>Землистый цвет кожи;</li>
                        <li>Повышенное слюноотделение и слезоточивость;</li>
                        <li>Раздражение слизистой верхних дыхательных путей;</li>
                        <li>Мокрый кашель;</li>
                        <li>Снижение фокуса внимания;</li>
                        <li>Провалы в памяти;</li>
                        <li>Головокружение;</li>
                        <li>Бессвязная речь и нарушение координации движений;</li>
                        <li>Вспыльчивость;</li>
                        <li>Апатия;</li>
                        <li>Необъяснимые и внезапные перепады настроения, раздражительность, рассеянность, вспышки гнева.</li>
                      </ul>
                      <br />
                      <h3>
                        Причины развития Токсикомании
                      </h3>
                      <br />
                      <ul style={{ marginLeft: '30px' }}>
                        <li>Желание уйти от реальности;</li>
                        <li>Неудовлетворение семейной жизнью;</li>
                        <li>Низкий уровень дохода;</li>
                        <li>Социальная несостоятельность;</li>
                        <li>Окружающие люди (нежелание оказаться вне коллектива, быть изолированным);</li>
                        <li>Генетическая предрасположенность;</li>
                        <li>Неправильное применение лекарственных препаратов в целях самолечения;</li>
                        <li>Снижение контроля или, наоборот, гиперопека со стороны взрослых;</li>
                        <li>Общедоступность токсических веществ;</li>
                        <li>Тяжелые заболевания, сопровождающиеся болями.</li>
                      </ul>
                      <br />
                      <h3>
                        Методы лечения Токсикомании
                      </h3>
                      <br />
                      <p>
                        Лечение токсикомании является трудным процессом, который сопровождается частыми рецидивами. Терапия исключает использование препаратов, которые воздействуют на обмен катехоламинов при причине высокой чувствительности к ним миокарда, вызванной употреблением.
                      </p>
                      <ul style={{ marginLeft: '30px' }}>
                      <br />
                        <li>Детоксикация организма и устранение абстиненции;</li>
                        <li>Когнитивная терапия и терапия психических расстройств;</li>
                        <li>Коррекция зависимости;</li>
                        <li>Поддерживающая терапия;</li>
                        <li>
                          Реабилитация больного в <b>Реабилитационных Центрах</b>. Программа восстановления включает вышеперечисленные процедуры,
                          прогулки, регулярные физические нагрузки, строгий режим питания, сна и бодрствования. Перемена обстановки, изменение
                          окружения, сбалансированные нагрузки оказывают целительное воздействие на психофизическое состояние пациента;
                        </li>
                        <li>Большое значение для профилактики заболевания имеет поддержание благоприятной психологической обстановки в семье.</li>
                      </ul>
                      <br />
                      <p>
                        Прогноз последствий токсикомании неблагоприятный из-за суицидальных случаев и тяжелого абстинентного синдрома. Употребление вредных веществ приводит к необратимым процессам головного мозга и нервной системы в целом.
                      </p>
                      <br />
                      <p>
                        Профилактика токсикомании практически такая же, как и при других наркологических зависимостях.
                      </p>
                      <br />
                      <p>
                        Самостоятельно справиться с токсикоманией невозможно. Помощь специалиста – это единственный способ, который поможет вновь вернуть близкого человека к нормальной жизни.
                      </p>
                      <br />
                      <p>
                        Признание наличия проблемы — это первый шаг на пути к выздоровлению, которое часто требует огромного мужества и силы.
                      </p>
                    </div>
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default InfoSection;
