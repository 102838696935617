import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import axiosClient from '../axiosClient';
import routes from "common/routes";
import footerRoutes from "common/footer-routes";
import { formatDistanceToNow } from 'date-fns';
import { ru } from 'date-fns/locale';
import Stack from "@mui/material/Stack";
import MKPagination from "components/Pagination/MKPagination";
import FilterForm from '../FilterForm';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';


const ForumPage = () => {
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem("forumPage");
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [totalPages, setTotalPages] = useState(1);
  const [filterValues, setFilterValues] = useState(() => {
    const savedFilters = localStorage.getItem("forumFilters");
    return savedFilters ? JSON.parse(savedFilters) : {
      categoryTypeId: '',
      ticketTypeId: '',
      description: '',
      isCreated: true,
      title: '',
      sort: 'desc'
    };
  });
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);

  const [categories, setCategories] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);

  useEffect(() => {
    const fetchCategoriesAndTypes = async () => {
      try {
        const response = await axiosClient.get("/api/common-reference/by-type/002");
        const data = response.data.data;
        const parentCategories = data.filter(item => item.parentId === null);
        setCategories(parentCategories);
        setTicketTypes(data.filter(item => item.parentId !== null));
      } catch (error) {
        console.error("Ошибка при загрузке категорий и типов:", error);
      }
    };
    fetchCategoriesAndTypes();
  }, []);

  const filterOptions = [
    { label: "Категория", id: "categoryTypeId", type: "select", options: categories },
    { label: "Тип", id: "ticketTypeId", type: "select", options: ticketTypes },
    { label: "Описание", id: "description", type: "text", options: [] },
    { label: "Наименование", id: "title", type: "text", options: [] },
  ];

  const fetchTickets = async (page, filters = {}) => {
    try {
      const response = await axiosClient.get('/api/ticket/by-filters', {
        params: {
          page: page - 1,
          size: 10,
          sort: 'desc',
          isCreated: true,
          ...filters,
        },
      });
      setTickets(response.data.data.content || []);
      setTotalPages(response.data.data.totalPages || 1);
    } catch (error) {
      console.error("Ошибка загрузки тем", error);
    }
  };

  const handleCreateTopic = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      navigate('/ticket/create');
    } else {
      navigate('/sign-in', { state: { message: 'Пожалуйста, войдите, чтобы создать тему.' } });
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilterValues(newFilters);
    setCurrentPage(1); 
    fetchTickets(1, newFilters);
  };

  useEffect(() => {
    fetchTickets(currentPage, filterValues); 
  }, [currentPage, filterValues]); 

  useEffect(() => {
    
    localStorage.setItem("forumFilters", JSON.stringify(filterValues));
    localStorage.setItem("forumPage", currentPage.toString());
  }, [filterValues, currentPage]);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        component="section"
        variant="gradient"
        position="relative"
        py={6}
        px={0}
        mx={0}
        pt={16}
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="row" spacing={12} justifyContent="center" mb={2}>
          <MKButton variant="contained" color="info" onClick={handleCreateTopic}>
            Создать тему
          </MKButton>
        </Stack>

        <Container
          maxWidth={false}
          disableGutters
          sx={{
            width: '90%',
            maxWidth: '90%',
            boxSizing: 'border-box',
          }}
        >
          <FilterForm
            filterOptions={filterOptions}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            onFilterChange={handleFilterChange}
          />

          {tickets.map((ticket) => (
            <Card
              key={ticket.id}
              sx={{
                p: 3,
                cursor: 'pointer',
                width: '100%',
                boxSizing: 'border-box',
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
              onClick={() => navigate(`/ticket/${ticket.id}`, { state: { ticket, avatar } })}
            >
              <Typography variant="body2" color="text.secondary">
                {ticket.categoryTypeName}
              </Typography>

              <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                {ticket.title}
              </Typography>

              <Typography variant="body2" color="text.secondary" sx={{ wordBreak: 'break-word' }}>
                {ticket.description && ticket.description.length > 100
                  ? `${ticket.description.slice(0, 300)}...`
                  : ticket.description || 'Нет описания'}
              </Typography>

              <MKBox
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 'auto',
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  @{ticket.userName}
                </Typography>

                <MKBox sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="caption" color="textSecondary">
                  {new Date(ticket.createdAt).toLocaleString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })}     
                  </Typography>
                  <ChatBubbleOutlineIcon fontSize="small" />
                  <Typography variant="caption" color="textSecondary">
                    {ticket.commentCount || 0}
                  </Typography>
                </MKBox>
              </MKBox>
            </Card>
          ))}
        </Container>

        <MKPagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
      </MKBox>
    </>
  );
};

export default ForumPage;