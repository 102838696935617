import React, { useState, useEffect } from 'react';
import { Card, Typography, Grid, Avatar, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { ru } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import axiosClient from '../axiosClient';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../api';

const TicketDetails = ({
  ticket: initialTicket,
  user,
  avatar
}) => {
  const [ticket, setTicket] = useState(initialTicket);
  const [canModerate, setCanModerate] = useState(false);
  const [moderated, setModerated] = useState(initialTicket.moderatorId !== null);
  const [ticketImage, setTicketImage] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const navigate = useNavigate();

  const currentUserRole = localStorage.getItem('role');
  const isOwner = user === ticket.userId;

  useEffect(() => {
    if (currentUserRole === 'ADMIN') {
      setCanModerate(true);
    }
    if (ticket.id) {
      fetchTicketImage(ticket.id);
    }
  }, [currentUserRole, ticket.id]);

  const fetchTicketImage = (ticketId) => {
    axiosClient.get(`${BASE_URL}/api/attachments/download/tickets/${ticketId}`, { responseType: 'arraybuffer' })
      .then(response => {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setTicketImage(imageUrl);
      })
      .catch(error => {
        console.error('Ошибка загрузки фото тикета:', error);
        setTicketImage(null);
      });
  };

  const handleModeration = async (ticketId, userId) => {
    try {
      await axiosClient.get(`api/ticket/moderate?id=${ticketId}&userId=${userId}`);
      setModerated(true);
      navigate(-1);
    } catch (error) {
      console.error('Ошибка при модерации:', error);
    }
  };

  const handleCloseClick = () => {
    setOpenCloseDialog(true);
  };

  const handleCloseConfirm = async () => {
    try {
      await axiosClient.get(`/api/ticket/close?id=${ticket.id}&userId=${user}`);
      setOpenCloseDialog(false);
      navigate(-1);
    } catch (error) {
      console.error('Ошибка при закрытии тикета:', error);
      setOpenCloseDialog(false);
    }
  };

  const handleCloseCancel = () => {
    setOpenCloseDialog(false);
  };

  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axiosClient.delete(`/api/ticket/${ticket.id}`);
      setOpenDeleteDialog(false);
      navigate(-1);
    } catch (error) {
      console.error('Ошибка при удалении тикета:', error);
      setOpenDeleteDialog(false);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleEditClick = () => {
    navigate('/ticket-edit', { state: { ticket } });
  };

  const getYoutubeEmbedUrl = (url) => {
    if (!url) return null;
    const videoIdMatch = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : null;
  };

  const youtubeEmbedUrl = getYoutubeEmbedUrl(ticket.youtubeLink);

  return (
    <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={12} sm={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar
            src={avatar}
            sx={{ width: 100, height: 100, mb: 1, transition: 'all 0.3s ease', '&:hover': { width: 120, height: 120 } }}
          />
          <Typography variant="bodyMedium" color="textPrimary">
            {ticket.userName}
          </Typography>
          {user !== ticket.userId && (
            <MKButton
              variant="contained"
              color="info"
              onClick={() => navigate('/chat', { state: { receiverId: ticket.userId } })}
              sx={{ mt: 2, padding: '4px 12px', fontSize: '0.75rem', width: 'auto' }}
            >
              Написать сообщение
            </MKButton>
          )}
          <MKBox sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="caption" color="textSecondary">
            {new Date(ticket.createdAt).toLocaleString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })}     
              </Typography>
          </MKBox>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Typography variant="h6" fontWeight="bold" noWrap>{ticket.title}</Typography>
          <Typography variant="body2" color="textSecondary" mb={2} sx={{ wordWrap: 'break-word' }}>
            {ticket.description}
          </Typography>
          {(ticketImage || youtubeEmbedUrl) && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {ticketImage && (
                <Grid item xs={12} md={6}>
                  <MKBox
                    sx={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      height: '300px',
                      width: '100%',
                    }}
                  >
                    <img
                      src={ticketImage}
                      alt="Фото тикета"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </MKBox>
                </Grid>
              )}
              {youtubeEmbedUrl && (
                <Grid item xs={12} md={ticketImage ? 6 : 12}>
                  <MKBox
                    sx={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      height: '300px',
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <iframe
                      src={youtubeEmbedUrl}
                      title="YouTube видео"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        border: 'none',
                      }}
                    />
                  </MKBox>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <MKBox sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2} 
              sx={{ alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}
            >
              {canModerate && ticket.statusName === 'На модерации' && (
                <MKButton
                  variant="contained"
                  color="info"
                  onClick={() => handleModeration(ticket.id, user)}
                  fullWidth={{ xs: true, sm: false }}
                >
                  Модерировать
                </MKButton>
              )}
              {((isOwner || currentUserRole === 'EMPLOYEE' || currentUserRole === 'ADMIN') && 
                (ticket.statusName !== 'Закрыт' && ticket.statusName !== 'Закрыт Администратором')) && (
                <>
                  <MKButton
                    variant="contained"
                    color="primary"
                    onClick={handleCloseClick}
                    fullWidth={{ xs: true, sm: false }}
                  >
                    Закрыть
                  </MKButton>
                  <MKButton
                    variant="contained"
                    color="info"
                    onClick={handleEditClick}
                    fullWidth={{ xs: true, sm: false }}
                  >
                    Редактировать
                  </MKButton>
                </>
              )}
              {(isOwner || currentUserRole === 'ADMIN') && (
                <MKButton
                  variant="contained"
                  color="error"
                  onClick={handleDeleteClick}
                  fullWidth={{ xs: true, sm: false }}
                >
                  Удалить
                </MKButton>
              )}
            </Stack>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ wordWrap: 'break-word', mt: { xs: 2, sm: 0 } }} 
            >
              {ticket.statusName}
            </Typography>
          </MKBox>
        </Grid>
      </Grid>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Подтверждение удаления"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Вы уверены, что хотите удалить эту тему? Это действие нельзя отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Отмена</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCloseDialog}
        onClose={handleCloseCancel}
        aria-labelledby="close-dialog-title"
        aria-describedby="close-dialog-description"
      >
        <DialogTitle id="close-dialog-title">
          {"Подтверждение закрытия"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="close-dialog-description">
            Вы уверены, что хотите закрыть эту тему? После закрытия его нельзя будет редактировать.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Отмена</Button>
          <Button onClick={handleCloseConfirm} color="primary" autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default TicketDetails;