import {React,useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {Fab,Collapse} from '@mui/material';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import whatsappIcon from "assets/images/whatsapp-icon.svg";
import ContactsGroup from "assets/images/contacts-fab.png";
import EmailIcon from "assets/images/email.png";

import telegramIcon from "assets/images/telegram.svg";
import CallIcon from "assets/images/call-blue.png";


function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const [open, setOpen] = useState(false);

  const toggleButtons = () => {
    setOpen(!open);
  };
  return (
    <>
    <MKBox component="footer">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5} sx={{ mb: 2, flexBasis: { xs: "30%", md: "25%" } }}>
            <MKBox ml={5}>
              <Link to={brand.route}>
                <MKBox
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="13rem"
                  mb={2}
                  ml="5%"
                />
              </Link>
              <MKTypography
                // prettier-ignore
                sx={{ fontSize: "13px" }}
                variant="h6"
              >
                {brand.name}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={2} ml={5}>
              {socials.map(({ icon, link }, index) => (
                <MKTypography
                  key={index}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={1.0} // Adjust the right margin as needed
                  sx={{ width: "2.5rem" }} // Adjust the width as needed
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={1.7} sx={{ mb: 3, ml: "10%", flexBasis: "25%" }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="none"
                mb={1}
                sx={{ fontSize: 17 }} // Set the font size here
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="none"
                        sx={{ fontSize: 17 }} // Set the font size here
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="none"
                        sx={{ fontSize: 17 }} // Set the font size here
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>

    <Fab
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        onClick={toggleButtons}
        aria-label="Open chat options"
        title="Открыть"
      >
        <img src={ContactsGroup} alt="Связаться" width={46} height={46} />
      </Fab>
      
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Fab
          sx={{
            position: 'fixed',
            bottom: 76,
            right: 16,
          }}
          aria-label="Whatsapp"
          title="Whatsapp"
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=996507183005&text&type=phone_number&app_absent="
        >
          <img src={whatsappIcon} alt="Whatsapp" width={46} height={46} />
        </Fab>

        <Fab
          sx={{
            position: 'fixed',
            bottom: 140,
            right: 16,
          }}
          aria-label="telegram"
          title="Телеграм"
          target="_blank"
          href="https://t.me/mayak_bishkek_bot"
        >
          <img src={telegramIcon} alt="Telegram" width={46} height={46} />
        </Fab>

        <Fab
  sx={{
    position: 'fixed',
    bottom: 268,  // Adjusted position below the "Call" button
    right: 16,
  }}
  aria-label="Email"
  title="Email"
  target="_blank"
  href="mailto:example@example.com" // Replace with your email address
>
  <img src={EmailIcon} alt="emailIcon" width={46} height={46} />
</Fab>

    

        <Fab
          sx={{
            position: 'fixed',
            bottom: 204,
            right: 16,
          }}
          aria-label="Позвонить"
          title="Позвонить"
          target="_blank"
          href="tel:+996507183005"
          >
          <img src={CallIcon} alt="callIcon" width={46} height={46} />
        </Fab>
      </Collapse>
    </>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
