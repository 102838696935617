import React, { useState, useEffect } from 'react';
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import routes from "common/routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import footerRoutes from "common/footer-routes";
import axiosClient from '../axiosClient';
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import DefaultFooter from "components/Footers/DefaultFooter";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'app/api';
import { CSSTransition } from "react-transition-group";
import MKAlert from "components/MKAlert";

const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [userIds, setUserIds] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [initialUserDetails, setInitialUserDetails] = useState({});
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosClient.get('/api/user/user', {
          params: { token },
        });
        setUserIds(response.data.data.id);
        setUserDetails(response.data.data);
        setInitialUserDetails(response.data.data); 
      } catch (error) {
        console.error(error);
      }
    };

    if (userIds) {
      fetchAvatar(userIds);
    }

    fetchUserDetails();
  }, [token, userIds]);

  const fetchAvatar = (userId) => {
    axios.get(`${BASE_URL}/api/attachments/download/avatar/user/${userId}`, {
      responseType: 'arraybuffer'
    })
      .then(response => {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setAvatar(imageUrl);
      })
      .catch(error => {
        console.error('Error loading avatar:', error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSave = async () => {
    const updatedDetails = {};
    
    if (userDetails.username !== initialUserDetails.username) updatedDetails.username = userDetails.username;
    if (userDetails.email !== initialUserDetails.email) updatedDetails.email = userDetails.email;
    if (userDetails.phone !== initialUserDetails.phone) updatedDetails.phone = userDetails.phone;
    if (userDetails.address !== initialUserDetails.address) updatedDetails.address = userDetails.address;
    if (userDetails.firstName !== initialUserDetails.firstName) updatedDetails.firstName = userDetails.firstName;
    if (userDetails.age !== initialUserDetails.age) updatedDetails.age = userDetails.age;
    if (userDetails.lastName !== initialUserDetails.lastName) updatedDetails.lastName = userDetails.lastName;
    
    updatedDetails.id = userDetails.id;

    if (Object.keys(updatedDetails).length <= 1) {
        setIsEditing(false);
        return;
    }

    try {
        const response = await axiosClient.put('/api/user/update', updatedDetails);
        
        setUserDetails(response.data.data);
        setIsEditing(false);
        setShowAlert(true);
        setAlertMessage("Профиль успешно обновлен!");
        setAlertType("success");
        setTimeout(() => setShowAlert(false), 3000);
    
    } catch (error) {
        setShowAlert(true);
        setAlertMessage(error.response?.data?.message || "Ошибка при обновлении пользователя");
        setAlertType("error");
        setTimeout(() => setShowAlert(false), 3000);
    }
};

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      axios.put(`${BASE_URL}/api/user/${userDetails.id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          setUserDetails((prevDetails) => ({ ...prevDetails, avatar: response.data.data }));
          fetchAvatar(userDetails.id);  
        })
        .catch(error => {
          setShowAlert(true);
          setAlertMessage("Ошибка при сохранении аватарки, повторите еще раз");
          setAlertType("error");
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        });
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox component="section" variant="gradient" position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2} pt={16}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} display="flex" flexDirection="column" alignItems="center">
              <Card sx={{ p: 3, alignItems: "center" }} >
                <Avatar 
                  src={avatar || 'default-avatar.jpg'} 
                  sx={{ width: 240, height: 240, cursor: 'pointer', backgroundSize: 'cover' }} 
                  onClick={() => document.getElementById('avatar-upload').click()} 
                />
                <input 
                  id="avatar-upload" 
                  type="file" 
                  style={{ display: 'none' }} 
                  onChange={handleAvatarChange} 
                />
                <Typography variant="h6" mt={2} textAlign={"center"}>{userDetails.username} </Typography>
                <Stack direction="row" spacing={2} mt={2}>
                  <MKButton variant="contained" color="info" onClick={() => navigate('/my-tickets')}>Мои темы</MKButton>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <MKBox display="grid" gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }} gap={2}>
                  <Typography variant="body2">Роль</Typography>
                  <MKInput
                    type="text"
                    name="role"
                    value={userDetails.role || ''}
                    disabled={true} // Role is always disabled
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Имя пользователя</Typography>
                  <MKInput
                    type="text"
                    name="username"
                    value={userDetails.username || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Телефон</Typography>
                  <MKInput
                    type="text"
                    name="phone"
                    value={userDetails.phone || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Адрес</Typography>
                  <MKInput
                    type="text"
                    name="address"
                    value={userDetails.address || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Email</Typography>
                  <MKInput
                    type="text"
                    name="email"
                    value={userDetails.email || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Возраст</Typography>
                  <MKInput
                    type="number"
                    name="age"
                    value={userDetails.age || 0}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Имя</Typography>
                  <MKInput
                    type="text"
                    name="firstName"
                    value={userDetails.firstName || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />

                  <Typography variant="body2">Фамилия</Typography>
                  <MKInput
                    type="text"
                    name="lastName"
                    value={userDetails.lastName || ''}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </MKBox>

                <Stack direction="row" spacing={2} justifyContent="center" mt={3}>
                  {!isEditing && (
                    <MKButton variant="contained" color="info" onClick={() => setIsEditing(true)}>Изменить</MKButton>
                  )}
                  {isEditing && (
                    <MKButton variant="contained" color="success" onClick={handleSave}>Сохранить</MKButton>
                  )}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <CSSTransition in={showAlert} timeout={300} classNames="fade" unmountOnExit>
        <div style={{ position: 'fixed', top: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 999 }}>
          <MKAlert color={alertType === 'success' ? 'success' : 'error'}>{alertMessage}</MKAlert>
        </div>
      </CSSTransition>
    </>
  );
};

export default ProfilePage;